import { Button, Grid, Typography } from "@material-ui/core";
import React from "react";
import { capitalize } from "lodash";
import moment from "moment";
import { timeFormat } from "../../util/time";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from '@material-ui/core/styles/colorManipulator';



const useStyles=makeStyles((theme)=>({
  root:{
    backgroundColor:fade(theme.palette.secondary.light,.0),
   // height:50,
   // borderBottom:`3px solid ${theme.palette.secondary.light}`,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    display:'flex',
    alignItems:'flex-end'
},
changeButton:{
  maxWidth:'200px'
}
}));

const Main = ({ trip }) => {
  const classes=useStyles();
  return (
    <div className={classes.root}>
    <Grid container spacing={1}>
      
        <Grid item xs={8} lg={8} md={8} sm={8} xl={8}>
          <Typography
            variant="h5"
            color="textSecondary"
            component="h5"
            
          >
            {`${capitalize(trip.departure)} - ${capitalize(trip.destination)}`}
          </Typography>
        
          <Typography
            variant="h6"
            color="textSecondary"
            component="h6"
            
          >
            {moment(trip.departureTime).format(timeFormat)}
          </Typography>
       
      </Grid>

      <Grid item xs={4} lg={4} md={4} sm={4} xl={4}>
        <Button
        className={classes.changeButton}
          // fullWidth
          size='small'
          variant="outlined"
          color="secondary"
          href={`/results/${trip.departure}/${trip.destination}/${new Date(
            trip.departureTime
          ).toDateString()}`}
        >
          change 
        </Button>
      </Grid>
    </Grid></div>
  );
};

export default Main;

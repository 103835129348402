import {
    ADD_MESSAGE,
    ADD_SUCCESS_MESSAGE,
    ADD_ERROR_MESSAGE,
    ADD_WARNING_MESSAGE,
    CLEAR_MESSAGE,
  } from "../constants/notification";
  
  export const addMessage = (message) => ({
    type: ADD_MESSAGE,
    message,
  });
  
  export const addSuccessMessage = (message) => ({
    type: ADD_SUCCESS_MESSAGE,
    message,
  });
  
  export const addErrorMessage = (message) => ({
    type: ADD_ERROR_MESSAGE,
    message,
  });

  export const addWarningMessage = (message) => ({
    type: ADD_WARNING_MESSAGE,
    message,
  });
  
  export const clearMessage = () => ({
    type: CLEAR_MESSAGE,
  });
import {
  TOGGLE_SELECTED,
  START_LOADING,
  ADD_SELECTED_SEAT,
  REMOVE_SELECTED_SEAT,
  LOAD_SEATS,
  LOAD_FOURTEEN_SEATER_MODEL,
  LOAD_THIRTY_THREE_SEATER_MODEL,
  LOAD_TWENTY_FIVE_SEATER_MODEL,
  END_LOADING,
  OPEN_DIALOG,
  UNSELECT_ALL_SEATS
} from "../constants/seats";
import { addErrorMessage, addMessage } from "../actions/notification";

export const toggleSelected = (position) => ({
  type: TOGGLE_SELECTED,
  position,
});

const startLoading = () => ({
  type: START_LOADING,
});

const addSelectedSeat = (selectedSeat) => ({
  type: ADD_SELECTED_SEAT,
  selectedSeat,
});

const removeSelectedSeat = (selectedSeat) => ({
  type: REMOVE_SELECTED_SEAT,
  selectedSeat,
});

const loadSeats = (seats) => ({
  type: LOAD_SEATS,
  seats,
});

const loadFourteenSeaterModel = (seats) => ({
  type: LOAD_FOURTEEN_SEATER_MODEL,
  seats,
});

const loadThirtyThreeSeaterModel = (seats) => ({
  type: LOAD_THIRTY_THREE_SEATER_MODEL,
  seats,
});

const loadTwentyFiveSeaterModel = (seats) => ({
  type: LOAD_TWENTY_FIVE_SEATER_MODEL,
  seats,
});

const endLoading = () => ({
  type: END_LOADING,
});

const openDialog=(isDialogOpen)=>({
type: OPEN_DIALOG,
isDialogOpen
});

const resetSeats=()=>({
  type: UNSELECT_ALL_SEATS,

})

export const closeDialog=()=>(dispatch)=>{
  dispatch(openDialog(false))
}

export const fetchSeats = (api, tripId) => (dispatch) => {
  dispatch(startLoading());

  const url = `/seats?tripId=${tripId}`;

  return api({ method: "GET", url })
    .then((response) => {
      dispatch(endLoading());

      dispatch(loadSeats(response.data.data));
    })
    .catch(({ message }) => {
      dispatch(endLoading());

      dispatch(addErrorMessage(message));
    });
};


export const selectSeats = (api, data) => (dispatch) => {
  dispatch(startLoading());

  const url = `/seats/select`;
 console.log('seats selection started')
  return api({ method: "PUT", url,data:{data:data} })
    .then((response) => {
      dispatch(endLoading());
      dispatch(openDialog(true));
   
      console.log('seats selection response', response)
     // dispatch(loadSeats(response.data.data));
    })
    .catch(({ message }) => {
      dispatch(endLoading());

      dispatch(addErrorMessage(message));
    });
};

export const unselectSeats = (api, data) => (dispatch) => {
  dispatch(startLoading());

  const url = `/seats/unSelect`;
 console.log('seats selection started')
  return api({ method: "PUT", url,data:{data:data} })
    .then((response) => {
      dispatch(endLoading());
      dispatch(openDialog(false))
      dispatch(addMessage('Your trip has been cancelled'));
      dispatch(resetSeats());
      console.log('seats un-selection response', response)
     // dispatch(loadSeats(response.data.data));
    })
    .catch(({ message }) => {
      dispatch(endLoading());

      dispatch(addErrorMessage(message));
    });
};

export const fetchFourteenSeaterModel = (api, tripId) => (dispatch) => {
  dispatch(startLoading());

  const url = `/seats?tripId=${tripId}`;

  return api({ method: "GET", url })
    .then((response) => {
      dispatch(endLoading());

      dispatch(loadFourteenSeaterModel(response.data.data));
    })
    .catch(({ message }) => {
      dispatch(endLoading());

      dispatch(addErrorMessage(message));
    });
};



export const fetchThirtyThreeSeaterModel = (api, tripId) => (dispatch) => {
  dispatch(startLoading());

  const url = `/seats?tripId=${tripId}`;

  return api({ method: "GET", url })
    .then((response) => {
      dispatch(endLoading());

      dispatch(loadThirtyThreeSeaterModel(response.data.data));
    })
    .catch(({ message }) => {
      dispatch(endLoading());

      dispatch(addErrorMessage(message));
    });
};

export const fetchTwentyFiveSeaterModel = (api, tripId) => (dispatch) => {
  dispatch(startLoading());

  const url = `/seats?tripId=${tripId}`;

  return api({ method: "GET", url })
    .then((response) => {
      dispatch(endLoading());

      dispatch(loadTwentyFiveSeaterModel(response.data.data));
    })
    .catch(({ message }) => {
      dispatch(endLoading());

      dispatch(addErrorMessage(message));
    });
};

export const updateSeatStatus = (selectedSeat) => (dispatch) => {
  dispatch(addSelectedSeat(selectedSeat));

  // dispatch(startLoading());

  // const url = `/seats/${seatId}`;

  // return api({ method: "PUT", url, data })
  //   .then(() => {
  //     dispatch(endLoading());

  //     switch (modelType) {
  //       case 14:
  //         dispatch(fetchFourteenSeaterModel(api, tripId));
  //         break;
  //       case 25:
  //         dispatch(fetchTwentyFiveSeaterModel(api, tripId));
  //         break;
  //       case 33:
  //         dispatch(fetchThirtyThreeSeaterModel(api, tripId));
  //         break;

  //       default:
  //         dispatch(fetchSeats(api, tripId));
  //         break;
  //     }
  //   })
  //   .catch(({ message }) => {
  //     dispatch(endLoading());

  //     dispatch(addErrorMessage(message));
  //   });
};

export const deleteSelectedSeat = (selectedSeat) => (dispatch) =>{
  dispatch(removeSelectedSeat(selectedSeat))
}

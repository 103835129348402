import { START_LOADING, LOAD, END_LOADING } from "../constants/trip";

const initialState = {
  isLoading: false,
  trip: {
    _id: "",
    vehicleno: "",
    departure: "",
    destination: "",
    insertedAt: new Date(),
    minPrice: 0,
    state: false,
    active: false,
    seats: 0,
    sold: 0,
    departureTime: new Date(),
  },
};

const main = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };

    case LOAD:
      return { ...state, trip: action.payload };

    case END_LOADING:
      return { ...state, isLoading: false };

    default:
      return state;
  }
};

export default main;

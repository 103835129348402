import React from "react";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchForm from "../../components/SearchForm";

const useStyles = makeStyles((theme) => ({
  heroContent: {
    backgroundColor: theme.palette.primary.main,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
}));

const Main = () => {
  const classes = useStyles();

  return (
    <div className={classes.heroContent}>
      <Container>
        <SearchForm />
      </Container>
    </div>
  );
};

export default Main;

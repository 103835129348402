import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid, Typography } from "@material-ui/core";
import GoogleBadge from "../../static/googlebadge.png";
import { motion } from "framer-motion";
import PrimaryLogo from "../../static/white-logo.png";
import AppMockup from "../../static/app.png";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    paddingTop: 30,
    height: "100vh",
    minHeight:"100vh",
    backgroundColor: "#fff",
    [theme.breakpoints.down("sm")]: {
      minHeight: "100vh",
      height: "100%",
      // marginBottom:40
    },
  },
  bgGradient: {
    height: "80%",
    // paddingTop:50,
    // position:'absolute',
    // top:'10px',
    // left:'10px',
    margin: "auto",
    backgroundImage: `-webkit-linear-gradient(-72deg, #ffffff 50%, ${theme.palette.primary.main} 50% )`,
    [theme.breakpoints.down("sm")]: {
      marginBottom: -40,
      backgroundImage: `-webkit-linear-gradient(-50deg, #ffffff 50%, ${theme.palette.primary.main} 50% )`,
    },
  },
  appScreen: {
    height: "500px",
    width: "280px",
    margin: "auto",
  },

  appTitle: {
    fontSize: "36px",
    fontWeight: 800,
    color: "#ffffff",
    marginRight: 20,
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      color: theme.palette.black,
    },
  },
  appSubtitle: {
    fontSize: "24px",
    fontWeight: 400,
    color: "#ffffff",
    marginRight: 20,
    margin: 10,
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      color: theme.palette.black,
    },
  },
  logo: {
    maxWidth: 200,
    marginRight: 10,
  },
  appMockup: {
    // marginTop: 250,
    // marginLeft: 60,
    maxWidth: "280px",
    maxHeight: "500px",
    WebkitBoxShadow: "0px 3px 26px -12px rgba(196,196,196,1)",
    MozBoxShadow: "0px 3px 26px -12px rgba(196,196,196,1)",
    boxShadow: "0px 3px 26px -12px rgba(196,196,196,1)",
    // height: "100%",
    //width: "280px",
  },
  logoContainer: {
    marginTop: theme.spacing(30),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(5),
    },
  },
  appDescription: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

const Main = () => {
  const classes = useStyles();

  const appScreenVariants = {
    hidden: {
      y: 0,
     
    },
    visible: {
      y: -20,
    
      
    },
    hover: {
      scale: 1.02,
      transition: {
        duration: 0.3,
        yoyo: "Inifinity",
      },
    },
   
  };

  const bounceTransition = {
    y: {
      duration: 4,
      yoyo: Infinity,
      ease: "easeInOut"
    },
    
    backgroundColor: {
      duration: 0,
      yoyo: Infinity,
      ease: "easeOut",
      repeatDelay: 0.8
    }
  };
  return (
    <div
     className={classes.root}
    >
      <div className={classes.bgGradient}>
        <Grid container spacing={0} justify="flex-start">
          <Box clone order={{ xs: 3, sm: 3, md: 2 }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <motion.div
                variants={appScreenVariants}
                initial="hidden"
                animate="visible"
                whileHover="hover"
                transition={bounceTransition}
                className={classes.appScreen}
              >
                <img
                  className={classes.appMockup}
                  src={AppMockup}
                  alt="booking app kenya"
                />
              </motion.div>
            </Grid>
          </Box>
          <Box clone order={{ xs: 2, sm: 2, md: 3 }}>
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <div className={classes.appDescription}>
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  className={classes.logoContainer}
                >
                  {" "}
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=ke.co.mobitillbooking"
                  >
                    <img
                      className={classes.logo}
                      src={GoogleBadge}
                      alt="logo"
                    />
                  </a>
                </motion.div>
                <Typography component="div" className={classes.appTitle}>
                  <Box>Kenya's leading bus app</Box>
                </Typography>
                <Typography component="div" className={classes.appSubtitle}>
                  <Box>We help customers make smarter journeys every day</Box>
                </Typography>
              </div>
            </Grid>
          </Box>
        </Grid>
      </div>
    </div>
  );
};

export default Main;

import axios from "axios";

const token = `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiJhNzI5YTI3ZS1hMzI5LTRiYzAtOTYwOC1mZGQxYzM3YWRlZjYiLCJpYXQiOjE1ODAyMTA1MzQsImV4cCI6MTg1MDIxMDUzNH0.OZtT-9gQAOi3RR1pBhR2U9B2RbUMGRG7uGN92J-JO8I`;

const config = {
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: { Authorization: token },
};

const instance = axios.create(config);

class APIError extends Error {
  constructor(statusCode, message, ...params) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIError);
    }

    this.statusCode = statusCode;
    this.message = message;
  }
}

const errorHandler = (err) => {
  try {
    if (err.response) {
      console.log(err);
      throw new APIError(err.response.status, err.response.data.message);
    } else if (err.request) {
      throw new APIError(503, err.request);
    } else throw APIError(400, err.message);
  } catch (e) {
    return e;
  }
};

instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(errorHandler(error))
);

export const api = instance;

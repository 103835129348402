import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import { capitalize } from "lodash";
import moment from "moment";
import { timeFormat } from "../../../../util/time";
import { formatNum } from "../../../../util/number";
import Logo from "../../../../static/white-logo.png";

Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 12,
    // paddingTop: 30,
    //paddingLeft: 60,
    // paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  section: {
    //flexGrow: 1,
    maxWidth: "30%",
    width: "30%",

    marginRight: 10,
  },
  title: {
    fontSize: 8,
    color: "#636e80",
    marginLeft: 0,
    fontWeight: 600,
    textDecoration: "none",
    //fontFamily: "Helvetica-Oblique",
  },
  content: {
    fontSize: 10,
    fontWeight: 600,
    // marginLeft: 5,
    marginBottom: 5,
    color: "#384354",
  },
  headerText: {
    fontSize: 10,
    fontWeight: "bold",
    // marginLeft: 5,
    // marginBottom: 5,
    color: "white",
    lineHeight: 1.2,
  },
  header: {
    height: 30,
    backgroundColor: "#bd232c",
    width: "100%",
  },
  tripIdStyle: {
    //flexGrow: 1,
    //  height: 20,
    // width: "100%",
    backgroundColor: "#e6e6e6",
  },
  smallText: {
    fontSize: 8,
    color: "#000000",
    marginLeft: 20,
    fontWeight: 500,
    marginTop: 3,
  },
  luggageStyle: {
    borderTop: "2px",
    borderTopColor: "#000000",
    borderTopStyle: "dashed",
    backgroundColor: "#e6e6e6",
  },
  luggageTitleText: {
    fontSize: 10,
    color: "#000000",
    marginLeft: 20,
    fontWeight: 700,
    marginTop: 3,
  },
  bodySection: {
    //paddingRight: 20,
    marginLeft: 15,
    padding: 5,
    flexDirection: "row",
  },
  image: {
    width: 80,
  },
  seatList: {
    display: "flex",
  },
});

// Create Document Component
const MyDocument = ({ ticketData, trip }) => {
  return (
    <Document>
      <Page size="A6" orientation="landscape" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.bodySection}>
            <View style={styles.section}>
              <Image
                object-fit="fill"
                style={styles.image}
                src={Logo}
                alt="logo"
              />
            </View>
            <View style={styles.section}>
              <Text style={styles.headerText}>{trip.saccoName}</Text>
              <Text style={styles.headerText}>{ticketData.vehicleno}</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.headerText}>Bus Ticket</Text>
            </View>
          </View>
        </View>
        <View style={styles.tripIdStyle}>
          <Text style={styles.smallText}>Trip ID: {ticketData.tripId}</Text>
        </View>
        <View style={styles.bodySection}>
          <View style={styles.section}>
            <Text style={styles.title}>From</Text>
            <Text style={styles.content}>
              {capitalize(ticketData.departure)}
            </Text>

            <Text style={styles.title}>To</Text>
            <Text style={styles.content}>
              {capitalize(ticketData.destination)}
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.title}>Departure date</Text>
            <Text style={styles.content}>
              {moment(ticketData.departureTime).format(timeFormat)}
            </Text>
          </View>

          <View style={styles.section}>
            <Text style={styles.title}>Total Seats</Text>

            <Text style={styles.content}>{ticketData.numberOfSeat}</Text>

            <Text style={styles.title}>Seat Numbers</Text>
            <View style={styles.seatList}>
            <Text style={styles.content} >
                  {ticketData.selectedSeatNo}
                </Text>
              {/* {" "}
              {ticketData.selectedSeatNo.map((seat) => (
                <Text style={styles.content} key={seat}>
                  {seat}
                </Text>
              ))} */}
            </View>
          </View>
        </View>
        <View style={styles.bodySection}>
          <View style={styles.section}>
            <Text style={styles.title}>Passenger</Text>
            <Text style={styles.content}>
              {`${capitalize(ticketData.passengerName)} ${capitalize(
                ticketData.nationality
              )}`}
            </Text>
            <Text style={styles.title}>Phone</Text>
            <Text style={styles.content}>{ticketData.phone}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.title}>Residence</Text>
            <Text style={styles.content}>{ticketData.residence}</Text>

            <Text style={styles.title}>ID / Passport No. </Text>
            <Text style={styles.content}>{ticketData.nationalId}</Text>
          </View>
          <View style={styles.section}>
            <Text style={styles.title}>Total fare</Text>
            <Text style={styles.content}>{`Ksh. ${formatNum(
              ticketData.amount
            )}/=`}</Text>
          </View>
        </View>
        {/* luggage tag */}
        {ticketData.hasLuggage ? (
          <View style={styles.tripIdStyle}>
            <Text style={styles.luggageTitleText}>LUGGAGE TAG</Text>
          </View>
        ) : null}
        {ticketData.hasLuggage ? (
          <View style={styles.bodySection}>
            <View style={styles.section}>
              <Text style={styles.title}>Passenger</Text>
              <Text style={styles.content}>
                {`${capitalize(ticketData.passengerName)} ${capitalize(
                  ticketData.nationality
                )}`}
              </Text>
              <Text style={styles.title}>Phone</Text>
              <Text style={styles.content}>{ticketData.phone}</Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.title}>From</Text>
              <Text style={styles.content}>
                {capitalize(ticketData.departure)}
              </Text>

              <Text style={styles.title}>To</Text>
              <Text style={styles.content}>
                {capitalize(ticketData.destination)}
              </Text>
            </View>
            <View style={styles.section}>
              <Text style={styles.title}>Bus Details</Text>
              <Text style={styles.content}>{ticketData.vehicleno}</Text>

              <Text style={styles.title}>Luggage description</Text>
              <Text style={styles.content}>
                {ticketData.luggageDescription}
              </Text>
            </View>
          </View>
        ) : null}
      </Page>
    </Document>
  );
};

export default MyDocument;

import React from 'react';
import { Button, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from '@material-ui/core/styles/colorManipulator';
import { connect } from "react-redux";
import { getTrips,getIsLoading } from "../../redux/accessors/search";
import LinearProgress from '@material-ui/core/LinearProgress';
import {useTheme} from '@material-ui/core/styles'



const useStyles=makeStyles((theme)=>({
root:{
    backgroundColor:fade(theme.palette.secondary.light,.3),
    //height:60,
    
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    display:'flex',
    alignItems:'flex-end'
},
busCount:{
    fontSize:'18px',
    fontWeight:600,
    color:theme.palette.primary.main
},
filterButton:{
    marginRight:10
}

}))




const Filters=({trips, isLoading})=>{
    const classes=useStyles();
    const theme=useTheme();
    return (
      <>
        <div className={classes.root} style={{borderBottom: `${isLoading?`none`: `3px solid ${theme.palette.secondary.light}`}`}}>
        {/* <Button className={classes.filterButton}
        variant='contained'
        color='secondary'
        >

        Filters
        </Button> */}

        <Typography
        component="div"
        className={classes.busCount}
        //align="center"
      >
        <Box lineHeight={1.3}>
          {" "}
          {`${trips.length}  bus${trips.length==1?"":"es"} available `}
        </Box>{" "}
      </Typography>
        </div>
        {isLoading?<LinearProgress/>:null}
        </>
    );
}


const mapState = (state) => ({
  trips: getTrips(state),
  isLoading:getIsLoading(state)
});

export default connect(mapState)(Filters);
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  LinearProgress,
  Typography,
} from "@material-ui/core";
import { validatePhoneNumber } from "../../../util/validators";
import Joi from "@hapi/joi";
import { useFormik } from "formik";
import { getTrip } from "../../../redux/accessors/trip";
import { connect } from "react-redux";
import { filter, flatten } from "lodash";
import {
  sendPaymentPrompt,
  confirmPayment,
} from "../../../redux/actions/payment";
import {
  getIsLoading,
  getRequest,
  getPaymentPromptSent,
} from "../../../redux/accessors/payment";
import { api } from "../../../config/api";
import { getPassengers } from "../../../redux/accessors/payment";
import CssTextField from "../../../components/CssTextField";
import { fade } from "@material-ui/core/styles/colorManipulator";
import { getPassenger } from "../../../redux/accessors/payment";
import { getSelectedSeats } from "../../../redux/accessors/seats";
import { green } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    margin: theme.spacing(4, 0),
    marginBottom: 0,
    marginTop: 0,
    paddingTop: 0,
    padding: theme.spacing(4, 2),
  },
  formControl: {
    //marginTop: theme.spacing(4),
  },
  submitButton: {
    marginTop: 20,
    marginBottom: 20,
    maxWidth: "350px",
    // margin: theme.spacing(4, 0),
  },
  confirmButton: {
    maxWidth: "350px",
    // margin: theme.spacing(4, 2),
  },
  paymentTitle: {
    fontSize: "16px",
    fontWeight: 600,
    color: theme.palette.secondary.dark,
    marginBottom: 20,
  },
  subTitle: {
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.secondary.dark,
    marginBottom: 10,
  },
  textField: {
    borderRadius: "5px",
    border: "none !important",
    maxWidth: "350px",
  },
  textInput: {
    backgroundColor: `${fade(theme.palette.secondary.light, 0.2)} !important`,
    "&::placeholder": {
      fontSize: "14px",
      fontWeight: 500,
    },
    fontSize: "14px",
    fontWeight: 600,
    borderRadius: "5px !important",
  },
  paymentInfo: {
    display: "flex",
    flexDirection: "column",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  loader:{
    maxWidth: "350px",
  }
}));

const Main = ({
  trip,
  sendPaymentPrompt,
  isLoading,
  request,
  confirmPayment,
  passenger,
  // currentSeatModel,
  selectedSeats,
  isPaymentPromptSent,
}) => {
  const classes = useStyles();
  const { object, string } = Joi.types();

  const [selectedOption, setSelectedOption] = useState("mpesa");

  const handleSelectedOption = (e) => setSelectedOption(e.target.value);

  // useEffect(() => {
  //   setSelectedSeats(filter(flatten(currentSeatModel), (s) => s && s.selected && !s.booked));
  // }, [currentSeatModel]);

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    let tmp = 0;
    flatten(selectedSeats).map((s) => {
      if (s && s.price) tmp = tmp + s.price;
      return tmp;
    });
    setTotalPrice(tmp);
  }, [setTotalPrice, selectedSeats]);

  const validationSchema = object.keys({
    phoneNumber: string
      .custom(validatePhoneNumber)
      .required()
      .label("Phone number"),
  });

  const validate = (values) => {
    const errors = {};

    const { error } = validationSchema.validate(values);

    if (error)
      error.details.map(({ path, message }, i) => (errors[path[i]] = message));

    return errors;
  };

  const initialValues = {
    phoneNumber: "",
  };

  const appid = "015cc0572c16e90f31";

  const formik = useFormik({
    initialValues,
    validate,
    onSubmit: ({ phoneNumber }) => {
      const data = {
        appid,
        payingPhone: phoneNumber,
        accountreference: trip.vehicleno,
        price: totalPrice,
      };

      return sendPaymentPrompt(api, selectedOption, { data });
    },
  });

  const onConfirmPayment = () => {
    const data = {
      accountreference: trip.vehicleno,
      amount: totalPrice,
      appid,
      payingPhone: formik.values.phoneNumber,
      request,
    };

    const booking = {
      appid,
      passengerName: `${passenger.firstName} ${passenger.lastName}`,
      nationality: passenger.nationality,
      residence: passenger.residence,
      phone: passenger.phone,
      tripId: trip._id,
      departureTime: trip.departureTime,
      vehicleno: trip.vehicleno,
      departure: trip.departure,
      destination: trip.destination,
      accountreference: trip.vehicleno,
      route: trip.routeId,
      nationalId: passenger.id,
      selectedSeatNo: selectedSeats.map((seat) => seat.seatNo),
      selectedSeatId: selectedSeats.map((seat) => seat._id),
      numberOfSeat: selectedSeats.length,
      payingPhone: formik.values.phoneNumber,
      request,
      amount: totalPrice,
      hasLuggage: passenger.hasLuggage,
      luggageDescription: passenger.luggageDescription,
    };

    return confirmPayment(api, selectedOption, { data }, booking);
  };

  return (
    <form className={classes.form} noValidate onSubmit={formik.handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12} sm={12} md={12} xl={12}>
          <Typography className={classes.paymentTitle} gutterBottom>
            Make payment
          </Typography>
        </Grid>
        <Grid item xs={12} lg={4} sm={4} md={4} xl={4}>
          <Typography className={classes.subTitle} gutterBottom>
            Choose payment method
          </Typography>
          <FormControl component="fieldset" className={classes.formControl}>
            {/* <FormLabel component="legend">Payment option</FormLabel> */}

            <RadioGroup
              aria-label="payment-option"
              name="paymentOption"
              value={selectedOption}
              onChange={handleSelectedOption}
              row
            >
              <FormControlLabel
              disabled={isLoading}
                value="mpesa"
                control={<Radio />}
                label="Mpesa"
              />

              <FormControlLabel
              disabled={isLoading}
                value="airtel"
                control={<Radio />}
                label="Airtel"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={8} sm={8} md={8} xl={8}>
         {isLoading ? <div className={classes.loader}>
            <Typography
              align="center"
              className={classes.subTitle}
              gutterBottom
            >
              Please wait...
            </Typography>
            <LinearProgress color="primary" />
          </div>:

          <div className={classes.paymentInfo}>
            <div className={classes.buttonProgress}></div>
            <Typography className={classes.subTitle} gutterBottom>
              Enter mobile money payment number
            </Typography>
            {/* <LinearProgress  color='primary'  /> */}
            <CssTextField
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Phone number"
              fullWidth
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phoneNumber}
              error={
                formik.touched.phoneNumber && formik.errors.phoneNumber
                  ? true
                  : false
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              required
              variant="outlined"
              className={classes.textField}
              InputProps={{
                classes: { input: classes.textInput },
                // startAdornment: (
                //   <InputAdornment position="start">
                //     <AccountCircleIcon className={classes.fromIcon} />
                //   </InputAdornment>
                // ),
              }}
            />

            <Button
              className={classes.submitButton}
              variant="contained"
              color="primary"
              type="submit"
              disabled={isLoading || isPaymentPromptSent}
            >
              send payment prompt
            </Button>

            <Button
              className={classes.confirmButton}
              variant="outlined"
              disabled={!request ? true : false}
              onClick={onConfirmPayment}
            >
              Confirm payment
            </Button>
          </div>}
        </Grid>
      </Grid>
    </form>
  );
};

const mapState = (state) => ({
  trip: getTrip(state),
  isLoading: getIsLoading(state),
  request: getRequest(state),
  passenger: getPassenger(state),
  selectedSeats: getSelectedSeats(state),
  isPaymentPromptSent: getPaymentPromptSent(state),
});

const mapDispatch = {
  sendPaymentPrompt,
  confirmPayment,
};

export default connect(mapState, mapDispatch)(Main);

export const START_LOADING = "START_LOADING";

export const LOAD = "LOAD";

export const END_LOADING = "END_LOADING";

export const START_LOADING_FROM_OPTIONS = "START_LOADING_FROM_OPTIONS";

export const LOAD_FROM_OPTIONS = "LOAD_FROM_OPTIONS";

export const END_LOADING_FROM_OPTIONS = "END_LOADING_FROM_OPTIONS";

export const START_LOADING_TO_OPTIONS = "START_LOADING_TO_OPTIONS";

export const LOAD_TO_OPTIONS = "LOAD_TO_OPTIONS";

export const END_LOADING_TO_OPTIONS = "END_LOADING_TO_OPTIONS";

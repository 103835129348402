const accessor = ({ reducer: { search } }) => search;

export const getIsLoading = (state) => accessor(state).isLoading;

export const getTrips = (state) => accessor(state).trips;

export const getFromIsLoading = (state) => accessor(state).fromIsLoading;

export const getFromOptions = (state) => accessor(state).fromOptions;

export const getToIsLoading = (state) => accessor(state).toIsLoading;

export const getToOptions = (state) => accessor(state).toOptions;

import React, { useState, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
//import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import { getType, getMessage } from "../redux/accessors/notification";
import { clearMessage } from "../redux/actions/notification";
import MuiAlert from '@material-ui/lab/Alert';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const Main = ({ type, message, clearMessage }) => {
  const [open, setOpen] = useState(false);

  const anchorOrigin = { vertical: "top", horizontal: "right" };
  const duration = 15000;

  useEffect(() => {
    message && setOpen(true);
  }, [type, message]);

  const handleClose = (e) => {
    clearMessage();

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={anchorOrigin}
    >
      <Alert onClose={handleClose} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const mapState = (state) => ({
  type: getType(state),
  message: getMessage(state),
});

const mapDispatch = {
  clearMessage,
};

export default connect(mapState, mapDispatch)(Main);

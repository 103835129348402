import React from "react";
import { Typography, Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as NoBusSVG } from "../static/svg/nobus.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    height: 250,
    width:'100%',
    display: "flex",
    justifyContent: "center",
    justifyItems: "center",
  },
  content: {
    margin: "auto",
   // height:200,
    

    alignItems: "center",
    justifyContent: "center",
    justifyItems: "center",
    display: "flex",
    flexDirection: "column",
    // backgroundColor: theme.palette.primary.main,
    // paddingBottom: theme.spacing(3),
  },
  title: {
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },
  subTitle: {
    color: theme.palette.secondary.main,
    fontSize: "12px",
    fontWeight: 500,
  },
  svg: {
    height: 100,
    width: 100,
  },
}));

const Main = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Typography
          align="center"
          gutterBottom
          component="div"
          className={classes.title}
        >
          <Box lineHeight={1}>No buses available</Box>{" "}
        </Typography>
        <Typography
          align="center"
          gutterBottom
          component="div"
          className={classes.subTitle}
        >
          <Box lineHeight={1}>Please try again later.</Box>{" "}
        </Typography>
        <NoBusSVG className={classes.svg} />
      </div>
    </div>
  );
};

export default Main;

export const START_LOADING = "START_LOADING";

export const LOAD_REQUEST = "LOAD_REQUEST";

export const END_LOADING = "END_LOADING";

export const ADD_PASSENGER = "ADD_PASSENGER";

export const PAYMENT_CONFIRMED='PAYMENT_CONFIRMED';

export const PAYMENT_PROMPT_SENT='PAYMENT_PROMPT_SENT';

export const TRIP_NEXT_STEP ='TRIP_NEXT_STEP';

export const TRIP_PREVIOUS_STEP ='TRIP_PREVIOUS_STEP';

export const TRIP_RESET_STEP ='TRIP_RESET_STEP';
import React from "react";
import {
  Switch,
  useLocation,
  Redirect,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Layout from "./components/Layout";
import Results from "./pages/results";
import Trip from "./pages/trip";
import Home from "./pages/home";
import NotFound from "./pages/notfound";
import { AnimatePresence } from "framer-motion";

const Main = () => {
  const location = useLocation();
  return (
    <AnimatePresence exitBeforeEnter>
     
        <Switch location={location} key={location.key}>
          <Layout exact path="/" children={<Home />} />

          <Layout path="/results/:from/:to/:date" children={<Results />} />

          <Layout path="/trip/:id" children={<Trip />} />
          {/* <Layout  path="/not-found" children={<NotFound/>} /> */}
         <Route component={NotFound} /> 
           
         
        </Switch>
     
    </AnimatePresence>
  );
};

export default Main;

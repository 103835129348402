import React, { useEffect, useState } from "react";
import {
  Button,
  Divider,
  Grid,
  Typography,
  Chip,
  Avatar,
  Paper,
  useMediaQuery,
  IconButton,
  SvgIcon,
} from "@material-ui/core";
import { connect } from "react-redux";
import {
  updateSeatStatus,
  deleteSelectedSeat,
} from "../../redux/actions/seats";
import { api } from "../../config/api";
import { useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { getSelectedSeats } from "../../redux/accessors/seats";

import { useTheme } from "@material-ui/core/styles";
import {
  blue,
  green,
  grey,
  orange,
  red,
  purple,
  
} from "@material-ui/core/colors";
import { filter, flatten, find } from "lodash";
import seat from "../../static/svg/seat.svg";
import goldseat from "../../static/svg/goldseat.svg";
import greenseat from "../../static/svg/greenseat.svg";
import blackseat from "../../static/svg/blackseat.svg";
import blueseat from "../../static/svg/blueseat.svg";
import redseat from "../../static/svg/redseat.svg";
import { ReactComponent as DriverSvg } from "../../static/svg/driver.svg";
import { ReactComponent as SeatSvg } from "../../static/svg/seat.svg";
import { motion } from "framer-motion";

const colorShade = 500;


const useStyles = makeStyles((theme) => ({
  root: {
    paddingRight: theme.spacing(5),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
    margin: 0,
  },

  paper: {
    margin: 0,
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    border: `2px solid ${theme.palette.secondary.light}`,
    [theme.breakpoints.down("sm")]: {
      padding: 10,
    },
  },
  noSeatType: {
    color: grey[colorShade],
    //  color: theme.palette.getContrastText(grey[colorShade]),
    [theme.breakpoints.down("xs")]: {
      maxWidth: "42px",
      // maxHeight: "30px",
      minWidth: "42px",
      //  minHeight: "30px",
    },
  },
  firstClass: {
    // color: green[colorShade],
    color:
      "linear-gradient(118deg, rgba(255,144,0,1) 0%, rgba(255,223,87,1) 52%, rgba(255,144,0,1) 100%)",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "42px",
      // maxHeight: "30px",
      minWidth: "42px",
      //  minHeight: "30px",
    },
    //marginRight:10
  },
  preniumClass: {
    color: orange[colorShade],
    // color: theme.palette.getContrastText(orange[colorShade]),
    [theme.breakpoints.down("xs")]: {
      maxWidth: "42px",
      // maxHeight: "30px",
      minWidth: "42px",
      //  minHeight: "30px",
    },
    //marginRight:10
  },
  economyClass: {
    color: blue[colorShade],
    //color: theme.palette.getContrastText(blue[colorShade]),
    [theme.breakpoints.down("xs")]: {
      maxWidth: "42px",
      // maxHeight: "30px",
      minWidth: "42px",
      //  minHeight: "30px",
    },
    //marginRight:10
  },
  selected: {
    color: purple[colorShade],
    //color: theme.palette.getContrastText(blue[colorShade]),
    [theme.breakpoints.down("xs")]: {
      maxWidth: "42px",
      // maxHeight: "30px",
      minWidth: "42px",
      //  minHeight: "30px",
    },
    //marginRight:10
  },
  selectedSeat: {
    color: red[colorShade],
    //color: theme.palette.getContrastText(red[colorShade]),
    [theme.breakpoints.down("xs")]: {
      maxWidth: "42px",
      // maxHeight: "30px",
      minWidth: "42px",
      //  minHeight: "30px",
    },
  },
  firstClassLabel: {
    // background: rgb(255,144,0);
    background:
      "linear-gradient(118deg, rgba(255,144,0,1) 0%, rgba(255,223,87,1) 52%, rgba(255,144,0,1) 100%)",
    //backgroundColor: green[colorShade],
    color: theme.palette.getContrastText(green[colorShade]),
    marginRight: 5,
    marginBottom: 5,
    fontWeight: 500,
    //marginRight:10
  },
  preniumClassLabel: {
    //backgroundColor: orange[colorShade],
    //background: rgb(31,31,31);
    background:
      "linear-gradient(249deg, rgba(31,31,31,1) 0%, rgba(151,151,151,1) 52%, rgba(31,31,31,1) 100%)",
    color: '#fff',
    marginRight: 5,
    marginBottom: 5,
    fontWeight: 500,
    //marginRight:10
  },
  economyClassLabel: {
    //background: rgb(0,114,236);
    background:
      "linear-gradient(249deg, rgba(0,114,236,1) 0%, rgba(20,75,132,1) 52%, rgba(0,114,236,1) 100%)",
    //backgroundColor: blue[colorShade],
    color: theme.palette.getContrastText(blue[colorShade]),
    marginRight: 5,
    marginBottom: 5,
    fontWeight: 500,
    //marginRight:10
  },
  selectedSeatLabel: {
    background:
      " linear-gradient(249deg, rgba(183,28,36,1) 0%, rgba(96,3,13,1) 52%, rgba(183,28,36,1) 100%)",
    //backgroundColor: red[colorShade],
    color: theme.palette.getContrastText(red[colorShade]),
    marginRight: 5,
    marginBottom: 5,
    fontWeight: 500,
  },
  reservedLabel: {
    marginRight: 5,
    marginBottom: 5,
    fontWeight: 500,
  },
  avatar: {
    backgroundColor: "white",
  },
  svgIcon: {
    [theme.breakpoints.up("md")]: {
      transform: "rotate(-90deg)",
    },
  },
  driverIcon: {
    [theme.breakpoints.up("md")]: {
      transform: "rotate(-90deg)",
    },
  },
  reserved:{
    color:'#E0E0E0'
  }
}));

const Main = ({
  selectedSeats,
  seats,
  toggleSelected,
  fetchSeats,
  currentSeatModel,
  trip,
  updateSeatStatus,
  deleteSelectedSeat,
  selectSeats,
}) => {
  const { params } = useRouteMatch();
  const classes = useStyles();
  const [selectedFirstClass, setSelectedFirstClass] = useState([]);
  const theme = useTheme();

  useEffect(() => {
    const total = filter(
      flatten(currentSeatModel),
      (s) => s && !s.booked && s.seatType === "firstClass"
    );
    setSelectedFirstClass(total);
  }, [currentSeatModel]);

  const [selectedPremiumClass, setSelectedPremiumClass] = useState([]);

  useEffect(() => {
    const total = filter(
      flatten(currentSeatModel),
      (s) => s && !s.booked && s.seatType === "preniumClass"
    );
    setSelectedPremiumClass(total);
  }, [currentSeatModel]);

  const [selectedEconomyClass, setSelectedEconomyClass] = useState([]);

  useEffect(() => {
    const total = filter(
      flatten(currentSeatModel),
      (s) => s && !s.booked && s.seatType === "economyClass"
    );
    setSelectedEconomyClass(total);
  }, [currentSeatModel]);

  //driver seat animation
  const driverIconTransition = {
    duration: 5,
    yoyo: "Infinity",
    ease: "easeInOut",
  };

  const driverIconVariants = {
    hidden: {
      x: 0,
      y: 0,
      rotate: -45,
    },
    visible: {
      x: 0,
      y: 0,
      rotate: 45,
    },
  };

  return (
    <>
      <Grid container spacing={2} direction="column">
        <Grid item>
          <div className={classes.legend}>
            <Chip
              label="Reserved"
              avatar={<Avatar></Avatar>}
              className={classes.reservedLabel}
            />
            <Chip
              label="First class"
              avatar={
                <Avatar className={classes.avatar}>
                  {selectedFirstClass.length}
                </Avatar>
              }
              className={classes.firstClassLabel}
            />

            <Chip
              label="Premium class"
              avatar={
                <Avatar className={classes.avatar}>
                  {selectedPremiumClass.length}
                </Avatar>
              }
              className={classes.preniumClassLabel}
            />
            <Chip
              label="Economy class"
              avatar={
                <Avatar className={classes.avatar}>
                  {selectedEconomyClass.length}
                </Avatar>
              }
              className={classes.economyClassLabel}
            />
            <Chip
              label="Selected"
              avatar={
                <Avatar className={classes.avatar}>
                  {selectedSeats.length}
                </Avatar>
              }
              className={classes.selectedSeatLabel}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
          <Paper className={classes.paper} elevation={0}>
            <Grid
              container
              spacing={1}
              direction={[theme.breakpoints.down("md")] ? "row-reverse" : "row"}
              justify="center"
            >
              {currentSeatModel.map((row, rowIndex) => (
                <Grid
                  item
                  key={rowIndex}
                  xs={currentSeatModel.length > 3 ? 2 : 3}
                  lg={12}
                  md={12}
                  sm={currentSeatModel.length > 3 ? 2 : 3}
                  xl={12}
                >
                  <Grid container spacing={2} justify="center">
                    {row.map(
                      (column, columnIndex) =>
                        column && (
                          <Grid item key={columnIndex}>
                            <Button
                              elevation={0}
                              style={
                                column.seatNo == 0
                                  ? {
                                      backgroundColor: "transparent",
                                      color: "transparent",
                                    }
                                  : {}
                              }
                              variant="text"
                              // className={

                              // }
                              disabled={
                                column.selected ||
                                column.disabled ||
                                column.booked ||
                                !column._id
                                  ? true
                                  : false
                              }
                              onClick={() =>
                                find(
                                  selectedSeats,
                                  (s) => s.seatNo === column.seatNo
                                )
                                  ? deleteSelectedSeat(column)
                                  : updateSeatStatus(column)
                              }
                            >
                              {column.seatNo == "DRV" ? (
                                <motion.div
                                  variants={driverIconVariants}
                                  animate="visible"
                                  transition={driverIconTransition}
                                >
                                  {" "}
                                  <SvgIcon className={classes.driverIcon}>
                                    <DriverSvg
                                      style={
                                        currentSeatModel.length < 3
                                          ? { transform: ["rotate(90deg)"] }
                                          : {}
                                      }
                                    />
                                  </SvgIcon>
                                </motion.div>
                              ) : column.seatNo == 0 ? (
                                <div style={{ opacity: 0 }}>
                                  {/* {column.seatNo} */}
                                  <SvgIcon>
                                    <img src={seat} alt="Logo" />
                                  </SvgIcon>
                                </div>
                              ) : (
                                // : find(
                                //     selectedSeats,
                                //     (s) => s.seatNo === column.seatNo
                                //   ) ?
                                // (
                                //   <div>
                                //     <SvgIcon className={classes.svgIcon}>
                                //       <GreenSeatSvg

                                //         // className={
                                //         //   find(
                                //         //     selectedSeats,
                                //         //     (s) => s.seatNo === column.seatNo
                                //         //   )
                                //         //     ? classes.selectedSeat
                                //         //     : column.selected
                                //         //     ? classes.selected
                                //         //     : column.seatType
                                //         //     ? classes[column.seatType]
                                //         //     : classes.noSeatType
                                //         // }
                                //       />
                                //     </SvgIcon>
                                //   </div>
                                // )
                                // : column.seatType == "preniumClass" ? (
                                //   <div>
                                //     <SvgIcon className={classes.svgIcon}>
                                //       <SeatSvg />
                                //     </SvgIcon>
                                //   </div>
                                // ) : column.seatType == "firstClass" ? (
                                //   <div>
                                //     <SvgIcon className={classes.svgIcon}>
                                //       <GoldSeatSvg />
                                //     </SvgIcon>
                                //   </div>
                                // ) : column.seatType == "economyClass" ? (
                                //   <div>
                                //     <SvgIcon className={classes.svgIcon}>
                                //       <BlueSeatSvg />
                                //     </SvgIcon>
                                //   </div>
                                // ) :
                                <div className={classes.svgIcon}>
                                  {/* <SvgIcon className={classes.svgIcon}> */}
                                  {
                                    find(
                                      selectedSeats,
                                      (s) => s.seatNo === column.seatNo
                                    ) ? (
                                      <img src={redseat} alt="Logo" />
                                    ) : column.seatType == "economyClass" &&
                                      !column.selected ? (
                                      <img src={blueseat} alt="Logo" />
                                    ) : column.seatType === "firstClass" &&
                                      !column.selected ? (
                                      <img src={goldseat} alt="Logo" />
                                    ) : column.seatType == "preniumClass" &&
                                      !column.selected ? (
                                      <img src={blackseat} alt="Logo" />
                                    ) : (
                                      <SvgIcon >
                                        <SeatSvg className={classes.reserved} />
                                      </SvgIcon>
                                    )
                                    //<img src={seat} alt="Logo" />
                                  }

                                  {/* {generateSeatSVG(column.seatType)} */}
                                  {/* </SvgIcon> */}
                                </div>
                              )}
                            </Button>
                          </Grid>
                        )
                    )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

const mapState = (state) => ({
  selectedSeats: getSelectedSeats(state),
});

const mapDispatch = {
  updateSeatStatus,
  deleteSelectedSeat,
};

export default connect(mapState, mapDispatch)(Main);

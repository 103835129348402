import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  Button,
  Typography,
  Paper,
} from "@material-ui/core";
import PassengerDetails from "./PassengerDetails";
import PaymentDetails from "./PaymentDetails";
import GetTicket from "./getTicket";
import TripSummary from "./TripSummary";
import {
  getPassenger,
  getPaymentConfirmed,
  getActiveStep,
} from "../../../redux/accessors/payment";
import { connect } from "react-redux";
import {
  handleNextStep,
  handlePreviousStep,
  handleResetStep,
  showTimeOutAlert
} from "../../../redux/actions/payment";
import {useHistory} from 'react-router-dom';

import MuiAlert from "@material-ui/lab/Alert";
function Alert(props) {
  return <MuiAlert elevation={0} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    marginBottom: theme.spacing(4),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    margin: theme.spacing(4, 0),
    padding: theme.spacing(4, 2),
  },
  stepperStyle: {
    marginTop: 0,
    paddingTop: 0,
    paddingBottom: 0,
  },
  warning: {
    color: theme.palette.black,
    marginBottom: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",

    fontSize: "16px",
  },
  timerText: {
    //maxWidth:50,
    marginLeft: 10,
  },
  timerContainer: {
    display: "flex",
  },
}));

function getSteps() {
  return ["Passenger details", "Trip Summary", "Payment options", "Get ticket"];
}

function getStepContent(
  stepIndex,
  currentSeatModel,
  selectedSeats,
  setSelectedSeats
) {
  switch (stepIndex) {
    // case 0:
    //   return <GetTicket />;
    case 0:
      return <PassengerDetails />;
    case 1:
      return (
        <TripSummary
          //  currentSeatModel={currentSeatModel}
          selectedSeats={selectedSeats}
        />
      );
    case 2:
      return (
        <PaymentDetails
          // currentSeatModel={currentSeatModel}
          selectedSeats={selectedSeats}
          setSelectedSeats={setSelectedSeats}
        />
      );
    case 3:
      return <GetTicket />;
    default:
      return "Unknown stepIndex";
  }
}

function Main({
  currentSeatModel,
  passenger,
  isPaymentConfirmed,
  activeStep,
  // getPassenger,
  // getPaymentConfirmed,
  // getActiveStep,
  tripNextStep,
  tripPreviousStep,
  tripResetStep,
  showTimeoutNotification,
  handleUnSelectSeats
}) {
  const classes = useStyles();
  const history = useHistory();
  // const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [selectedSeats, setSelectedSeats] = React.useState([]);
  // const [isActive, setIsActive] = useState(false);

  console.log("active step", activeStep);

  //counter
  const [counter, setCounter] = useState(600);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);


  const clearSeats=async()=>{
    if(counter<=0){
      await handleUnSelectSeats()
      history.go(-2)
      showTimeoutNotification('Time is out')
      
    }
  }

  useEffect(()=>{
    clearSeats();
  })
  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  function secondsToHms(d) {
    d = Number(d);
    var h = Math.floor(d / 3600);
    var m = Math.floor(d % 3600 / 60);
    var s = Math.floor(d % 3600 % 60);

    var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
    var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
    var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
    return hDisplay + mDisplay + sDisplay; 
}

  return (
    <div className={classes.root}>
      <Alert severity="warning" className={classes.warning}>
        <div className={classes.timerContainer}>
         
          <Typography>
            {" "}
            Please complete your booking process in 10 MINUTES. Your seat
            selection will be released to others in 10 MINUTES.
          </Typography>
          {" "}
          <Typography variant="h5" className={classes.timerText}>
            {" "}
            {`${secondsToHms(counter)}  remaining`}{" "}
          </Typography>
        </div>
      </Alert>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        className={classes.stepperStyle}
      >
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>
              All steps completed
            </Typography>
            <Button onClick={tripResetStep}>Reset</Button>
          </div>
        ) : (
          <div>
            <Paper elevation={0} className={classes.paper}>
              {getStepContent(
                activeStep,
                currentSeatModel,
                selectedSeats,
                setSelectedSeats,
                isPaymentConfirmed
              )}
            </Paper>

            <div>
              {activeStep >= 2 ? null : (
                <Button
                  disabled={activeStep === 0 || isPaymentConfirmed}
                  onClick={tripPreviousStep}
                  className={classes.backButton}
                >
                  Back
                </Button>
              )}
              {activeStep >= 2 ? null : (
                <Button
                  disabled={passenger.firstName.length < 2 ? true : false}
                  variant="contained"
                  color="primary"
                  onClick={tripNextStep}
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapState = (state) => ({
  passenger: getPassenger(state),
  isPaymentConfirmed: getPaymentConfirmed(state),
  activeStep: getActiveStep(state),
});

const mapDispatch = {
  tripNextStep: handleNextStep,
  tripPreviousStep: handlePreviousStep,
  tripResetStep: handleResetStep,
  showTimeoutNotification: showTimeOutAlert,
};

export default connect(mapState, mapDispatch)(Main);

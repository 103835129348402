import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PrimaryLogo from "../static/primary-logo.png";
import DilLogo from "../static/dilLogo.png";
import { Grid, Typography, Box, TextField, Button } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import Contact from "./Contact";
import Copyright from "../components/Copyright";
import clsx from "clsx";
import GoogleBadge from "../static/googlebadge.png";

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: grey[100],
    margin: 0,

    padding: theme.spacing(6),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: grey[100],
  },
  logo: {
    maxWidth: 150,
    marginTop: 10,
    maxHeight: 100,
  },
  dilLogo: {
    maxWidth: 80,
    // filter: 'grayscale(50%)'
    // backgroundColor:'red'
  },
  googlebadge: {
    marginTop: 10,
    maxWidth: 200,
  },
  title: {
    fontSize: "20px",
    fontWeight: 700,
    marginLeft: theme.spacing(1),
  },
  bodyText: {
    fontSize: "14px",
  },
  email: {
    display: "flex",
    marginTop: 20,
    alignItems: "center",
    marginBottom: 20,
  },
  emailTextField: {
    marginRight: 10,
    height: 40,
  },
  emailButton: {
    height: 36,
  },
  link: {
    fontSize: "16px",
    fontWeight: 600,
    marginBottom: 20,
  },
  quickLinks: {
    paddingLeft: 30,
  },
  copyRight: {
    padding: theme.spacing(2),
    //maxHeight: 60,
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    alignContent: "center",
  },
  logos: {
    display: "flex",
    alignItems:"center"
  },
}));

const Main = () => {
  const classes = useStyles();

  return (
    <footer>
      <div className={classes.footer}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6} sm={12} xl={6} md={6}>
            <Typography component="div" className={classes.title}>
              <Box lineHeight={1}>Stay in touch</Box>{" "}
            </Typography>

            {/* <Typography component="div" className={classes.bodyText}>
              <Box lineHeight={1}>
                Subscribe to our newsletter and get latest news and
                notifications.
              </Box>{" "}
            </Typography>
            <div className={classes.email}>
              <TextField
                id="email"
                name="email"
                //   label="Email"
                placeholder="Type your email here..."
                className={classes.emailTextField}
                variant="outlined"
                size="small"
              />
              <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.emailButton}
              >
                Submit
              </Button>
            </div> */}

            {/* <img className={classes.googlebadge} src={GoogleBadge} alt="playstore-badge" /> */}
            <Contact isAddressVisible={false} isSocialMediaVisible={true} />
            <div className={classes.logos}>
              <div>
                <img className={classes.logo} src={PrimaryLogo} alt="logo" />
              </div>
              <img
                className={clsx(classes.logo, classes.dilLogo)}
                src={DilLogo}
                alt="logo"
              />{" "}
            </div>
          </Grid>
          {/* <Grid
            item
            xs={12}
            lg={4}
            sm={12}
            xl={4}
            md={4}
            className={classes.quickLinks}
          >
             <Contact />
            <Typography gutterBottom component="div" className={classes.link}>
              <Box lineHeight={1}>About Us</Box>{" "}
            </Typography>
            <Typography gutterBottom component="div" className={classes.link}>
              <Box lineHeight={1}>Our Partners</Box>{" "}
            </Typography>
            <Typography gutterBottom component="div" className={classes.link}>
              <Box lineHeight={1}>Testmonials</Box>{" "}
            </Typography>
            <Typography gutterBottom component="div" className={classes.link}>
              <Box lineHeight={1}>FAQs</Box>{" "}
            </Typography>
          </Grid> */}
          <Grid item xs={12} lg={6} sm={12} xl={6} md={6}>
            <Typography gutterBottom component="div" className={classes.title}>
              <Box lineHeight={1}>Contact Us</Box>{" "}
            </Typography>
            <Contact isAddressVisible={true} isSocialMediaVisible={false} />
          </Grid>
        </Grid>
      </div>

      <div className={classes.copyRight}>
        <Copyright />
      </div>
    </footer>
  );
};
export default Main;

import React from "react";
import { motion } from "framer-motion";
import { makeStyles } from "@material-ui/core/styles";
import {Typography} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "auto",
    backgroundColor: "yellow",
    height: "100vh",
    width: "100vw",
  },
}));

const Main = () => {
  const classes = useStyles();
  const containerVariants = {
    hidden: {
      opacity: 0.5,
      scale: 1.2,
    },
    visible: {
      opacity: 1,
      scale: 1,
      transition: { delay: 0, duration: 0.5 },
    },
    exit: {
      x: "-100vw",
      transition: {
        duration: 0.8,
        ease: "easeInOut",
      },
    },
  };
  return (
    <motion.main
      className={classes.root}
    //   variants={containerVariants}
    //   initial="hidden"
    //   animate="visible"
    //   exit="exit"
    >
      <Typography>
        Error 404
      </Typography>
    </motion.main>
  );
};

export default Main;

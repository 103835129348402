import { START_LOADING, LOAD, END_LOADING } from "../constants/trip";
import { addErrorMessage } from "../actions/notification";

const startLoading = () => ({
  type: START_LOADING,
});

const load = (payload) => ({
  type: LOAD,
  payload,
});

const endLoading = () => ({
  type: END_LOADING,
});

export const fetchTrip = (api, id) => (dispatch) => {
  dispatch(startLoading());

  return api({ method: "GET", url: `/trips/${id}` })
    .then(({ data: { data } }) => {
      dispatch(endLoading());

      dispatch(load(data));
    })
    .catch(({ message }) => {
      dispatch(endLoading());

      dispatch(addErrorMessage(message));
    });
};

const accessor = ({ reducer: { seats } }) => seats;

export const getSelectedSeats = (state) => accessor(state).selectedSeats;

export const getSeats = (state) => accessor(state).seats;

export const getIsLoading = (state) => accessor(state).isLoading;

export const getFourteenSeaterModel = (state) =>
  accessor(state).fourteenSeaterModel;

export const getThirtyThreeSeaterModel = (state) =>
  accessor(state).thirtyThreeSeaterModel;

export const getTwentyFiveSeaterModel = (state) =>
  accessor(state).twentyFiveSeaterModel;

export const getOpenDialog=(state)=> accessor(state).isDialogOpen;

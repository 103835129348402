import React from "react";
import {
  
  
  LinkedIn as LinkedInIcon,
  Facebook as FacebookIcon,
  Twitter as TwitterIcon,
  Instagram as InstagramIcon,
  YouTube as YouTubeIcon,
} from "@material-ui/icons";
import PhoneIcon from '@material-ui/icons/Phone';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  TextField,
  Button,
  Link,
  makeStyles,
} from "@material-ui/core";
import {grey} from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  avatar: {
    color: theme.palette.primary.main,
    backgroundColor: "inherit",
  },
  listItem:{
    marginLeft:0,
    paddingLeft:0
  },
  icon:{
    height:30,
    width:30,
    
  },
  contactIcon:{
    color:grey[700]
  }
}));

export const SocialMedia = () => {
  const classes = useStyles();
  const items = [
    {
      alt: "facebook",
      href: "https://www.facebook.com/dataintegratedltd/",
      icon: <FacebookIcon className={classes.icon} />,
    },
    {
      alt: "twitter",
      href: "https://twitter.com/DataIntegrated",
      icon: <TwitterIcon className={classes.icon} />,
    },
    {
      alt: "linkedin",
      href: "https://www.linkedin.com/company/27253906/",
      icon: <LinkedInIcon className={classes.icon}/>,
    },
    {
      alt: "instagram",
      href: "https://www.instagram.com/dataintegratedlimited/",
      icon: <InstagramIcon className={classes.icon}/>,
    },
    {
      alt: "youtube",
      href: "https://www.youtube.com/channel/UCjpZhDUUwjG5BJjxzjHMrdg",
      icon: <YouTubeIcon className={classes.icon}/>,
    },
  ];

  return (
    <Grid container spacing={2}>
      {items.map((item, index) => (
        <Grid item key={index}>
          <Avatar
            component={Link}
            alt={item.alt}
            href={item.href}
            className={classes.avatar}
          >
            {item.icon}
          </Avatar>
        </Grid>
      ))}
    </Grid>
  );
};

const Main = ({isAddressVisible,isSocialMediaVisible}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12} sm={12} xl={12} md={12}>
       {isAddressVisible? <List >
          <ListItem className={classes.listItem}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <PhoneIcon className={classes.contactIcon}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="Phone" secondary="0711 520 705" />
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <LocationOnIcon className={classes.contactIcon}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Location"
              secondary="P.O. Box 17615-00500 Enterprise, Industrial Area."
            />
          </ListItem>

          <ListItem className={classes.listItem}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <LocationCityIcon className={classes.contactIcon}/>
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Office"
              secondary="2nd Floor, Avon Centre, Gearbox Building
  "
            />
          </ListItem>
        </List>:null}
      </Grid>

     

      <Grid item xs={12} lg={12} sm={12} xl={12} md={12}>
       {isSocialMediaVisible? <SocialMedia classes={classes} />:null}
      </Grid>
    </Grid>
  );
};

export default Main;

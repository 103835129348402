import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Grid, Paper, Chip, Avatar } from "@material-ui/core";
import TripInfo from "./TripInfo";
import Seats from "./Seats";

import Payment from "./payment";
import { connect } from "react-redux";
import {
  getSelectedSeats,
  getSeats,
  getFourteenSeaterModel,
  getThirtyThreeSeaterModel,
  getTwentyFiveSeaterModel,
} from "../../redux/accessors/seats";
import { flatten, filter } from "lodash";
import SelectedRoute from "./SelectedRoute";
import {
  fetchSeats,
  fetchFourteenSeaterModel,
  fetchThirtyThreeSeaterModel,
  fetchTwentyFiveSeaterModel,
} from "../../redux/actions/seats";
import { getTrip } from "../../redux/accessors/trip";
import { useRouteMatch } from "react-router-dom";
import { api } from "../../config/api";
import { fetchTrip } from "../../redux/actions/trip";
import TripSubHeader from "./TripSubHeader";
import { motion } from "framer-motion";
import PassengerSubHeader from "./PassengerSubHeader";
import { ScrollingProvider, Section } from "react-scroll-section";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "white",
  },
  cardGrid: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(8),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),

    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    border: `2px solid ${theme.palette.secondary.light}`,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  routeItem: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    //border: `2px solid ${theme.palette.secondary.light}`,
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
}));

const Main = ({
  seats,
  fetchSeats,
  fetchFourteenSeaterModel,
  fourteenSeaterModel,
  fetchThirtyThreeSeaterModel,
  thirtyThreeSeaterModel,
  fetchTwentyFiveSeaterModel,
  twentyFiveSeaterModel,
  trip,
  fetchTrip,
  selectedSeats,
}) => {
  const classes = useStyles();
  // const [selectedSeats, setSelectedSeats] = useState([]);
  const [currentSeatModel, setCurrentSeatModel] = useState(seats);
  const { params } = useRouteMatch();

  useEffect(() => {
    switch (trip.seats) {
      case 25:
        setCurrentSeatModel(twentyFiveSeaterModel);
        break;
      case 33:
        setCurrentSeatModel(thirtyThreeSeaterModel);
        break;

      default:
        setCurrentSeatModel(fourteenSeaterModel);
        break;
    }
  }, [
    trip,
    setCurrentSeatModel,
    fourteenSeaterModel,
    thirtyThreeSeaterModel,
    twentyFiveSeaterModel,
  ]);

  useEffect(() => {
    switch (trip.seats) {
      case 25:
        fetchTwentyFiveSeaterModel(api, params.id);
        break;
      case 33:
        fetchThirtyThreeSeaterModel(api, params.id);
        break;

      default:
        fetchFourteenSeaterModel(api, params.id);
        break;
    }
  }, [
    params.id,
    fetchSeats,
    fetchFourteenSeaterModel,
    fetchThirtyThreeSeaterModel,
    fetchTwentyFiveSeaterModel,
    trip,
  ]);

  // useEffect(() => {
  //   setSelectedSeats(filter(flatten(currentSeatModel), (s) => s && s.selected && !s.booked));
  // }, [currentSeatModel]);

  useEffect(() => {
    fetchTrip(api, params.id);
  }, [params.id, fetchTrip]);

  const containerVariants = {
    hidden: {
      opacity: 0.5,
      scale:1.2
    },
    visible: {
      opacity: 1,
      scale:1,
      transition: { delay: 0.0, duration: 0.5 },
    },
    exit: {
      x: "-100vw",
      transition: {
        duration:0.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.div
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className={classes.root}>
        <SelectedRoute trip={trip} />

        <TripSubHeader />
        <div className={classes.cardGrid}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Seats currentSeatModel={currentSeatModel} trip={trip} />
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <TripInfo trip={trip} currentSeatModel={currentSeatModel} />
            </Grid>
          </Grid>
        </div>
      </div>
      <Section id="payment">
        {/* {selectedSeats.length > 0 && (
            <div className={classes.root}>
              <PassengerSubHeader />
              <div className={classes.cardGrid}>
                <Grid item xs={12} lg={12}>
                  <Payment currentSeatModel={currentSeatModel} />
                </Grid>
              </div>
            </div>
          )} */}
      </Section>
    </motion.div>
  );
};

const mapState = (state) => ({
  selectedSeats: getSelectedSeats(state),
  seats: getSeats(state),
  fourteenSeaterModel: getFourteenSeaterModel(state),
  thirtyThreeSeaterModel: getThirtyThreeSeaterModel(state),
  twentyFiveSeaterModel: getTwentyFiveSeaterModel(state),
  trip: getTrip(state),
});

const mapDispatch = {
  fetchSeats,
  fetchFourteenSeaterModel,
  fetchThirtyThreeSeaterModel,
  fetchTwentyFiveSeaterModel,
  fetchTrip,
};

export default connect(mapState, mapDispatch)(Main);

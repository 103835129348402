import React from "react";
import { makeStyles, withStyles } from "@material-ui/styles";
import { Button, CircularProgress } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { unselectSeats } from "../../../redux/actions/seats";

const useStyles = makeStyles((theme) => ({
  cancelButton: {
    borderColor: "#fff",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#fff",
      color: theme.palette.primary.main,
    },
  },
  wrapper: {
    position: "relative",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

const LightTooltip = withStyles((theme) => ({
  tooltip: { color: theme.palette.white, backgroundColor: "#00000080" },
}))(Tooltip);

export default function CancelTripDialog(props) {
  const { handleUnSelectSeats, isLoading, trip } = props;

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  console.log("trip", trip);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const unselectSeats = async () => {
    await handleUnSelectSeats();
    setOpen(false);
  };

  // const handleUnselectClose = () => {
  //   const seatIds = selectedSeats.map((seat) => seat._id);
  //   unselectSeats(api, seatIds)
  // };

  return (
    <div>
      <LightTooltip
        disableFocusListener
        title="Reset seat selection and trip information"
      >
        <Button
          size="small"
          variant="outlined"
          className={classes.cancelButton}
          onClick={handleClickOpen}
        >
          Cancel Trip
        </Button>
      </LightTooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Reset seat selection and cancel trip?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By clicking "YES" button, your seat selection will be reset and trip
            details dropped. You will be required to make an new selection.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            disabled={isLoading ? true : false}
          >
            Dismiss
          </Button>
          <div>
            <div className={classes.wrapper}>
              <Button
                onClick={unselectSeats}
                color="secondary"
                autoFocus
                disabled={isLoading ? true : false}
                //href={`/results/${trip.departure}/${trip.destination}/${new Date(trip.departureTime).toDateString()}`}
              >
                Yes
              </Button>
              {isLoading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

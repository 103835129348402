import {withStyles } from '@material-ui/styles';
import {TextField} from '@material-ui/core';

const  CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: '#000000'
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: '#00000000'
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#00000000'
        },
        '&:hover fieldset': {
          borderColor: '#00000000'
        },
        '&.Mui-focused fieldset': {
          borderColor: '#00000000'
        }
      }
    }
  })(TextField);

  export default CssTextField;
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardHeader,
  Avatar,
  CardContent,
  Typography,
  Grid,
  Divider,
  Box,
} from "@material-ui/core";
import { red, green } from "@material-ui/core/colors";
import { connect } from "react-redux";
import { capitalize } from "lodash";
import { getTrip, getIsLoading } from "../../redux/accessors/trip";
import { fetchTrip } from "../../redux/actions/trip";
import { api } from "../../config/api";
import { useRouteMatch } from "react-router-dom";
import moment from "moment";
import { LocationCityOutlined } from "@material-ui/icons";
import { timeFormat } from "../../util/time";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PrimaryLogo from "../../static/primary-logo.png";
import SelectedSeats from "./SelectedSeats";

const useStyles = makeStyles((theme) => ({
  card: {
    // height: "100%",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      marginLeft: 0,
      marginRight: 0,
    },
  },
  avatar: {
    backgroundColor: red[500],
  },
  cardContent: {
    paddingTop: 10,
    // flexGrow: 1,
  },
  pos: {
    marginLeft: 12,
    fontWeight: 500,
  },
  location: {
    paddingLeft: 10,
    marginBottom: 10,
  },
  locationText: {
    fontSize: "16px",
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },
  locationDiv: {
    display: "flex",
    alignItems: "center",
    //marginBottom:10
  },
  fromLocationIcon: {
    color: theme.palette.primary.main,
    fontSize: "18px",
    fontWeight: 900,
    marginRight: 5,
  },
  toLocationIcon: {
    color: green[700],
    fontSize: "20px",
    fontWeight: 900,
    marginRight: 4,
  },
  divider: {
    height: 20,
    width: 2,
    backgroundColor: "none",
    marginLeft: 8,
    margin: 5,
    border: `1px dashed ${theme.palette.secondary.light}`,
  },
  logo: {
    maxWidth: 100,
  },
  header: {
    height: 50,

    paddingTop: 20,
    paddingBottom: 10,
  },
  title: {
    fontSize: "16px",
    fontWeight: 600,
    color: theme.palette.black,
    flexGrow: 1,
  },
  companyName: {
    display: "flex",
  },
  dateTime: {
    marginTop: 10,
  },
}));

const Main = ({ trip, fetchTrip, isLoading, currentSeatModel }) => {
  const classes = useStyles();

  console.log(isLoading);

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<img className={classes.logo} src={PrimaryLogo} alt="logo" />}
        classes={{
          root: classes.header,
        }}
        action={
          <Typography className={classes.dateTime} color="textSecondary">
            {moment(trip.departureTime).format(timeFormat)}
          </Typography>
        }
        //  title={capitalize(trip.saccoName)}
        // subheader={`${capitalize(trip.departure)} - ${capitalize(
        //   trip.destination
        // )}`}
      />

      <CardContent className={classes.cardContent}>
        <Grid container spacing={2}>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            className={classes.companyName}
          >
            <Typography
              component="div"
              className={classes.title}
              //align="center"
            >
              <Box> {capitalize(trip.saccoName)}</Box>{" "}
            </Typography>

            <Typography className={classes.pos} color="textSecondary">
              {`${trip.seats - trip.sold} seats left`}
            </Typography>
          </Grid>
          <Grid
            item
            xl={12}
            lg={12}
            md={12}
            xs={12}
            sm={12}
            className={classes.location}
          >
            <div className={classes.locationDiv}>
              <LocationSearchingIcon className={classes.fromLocationIcon} />{" "}
              <Typography
                component="div"
                className={classes.locationText}
                //align="center"
              >
                <Box lineHeight={1.3}> {capitalize(trip.departure)}</Box>{" "}
              </Typography>
            </div>
            <Divider
              orientation="vertical"
              flexItem
              className={classes.divider}
            />
            <div className={classes.locationDiv}>
              <LocationOnIcon className={classes.toLocationIcon} />{" "}
              <Typography
                component="div"
                className={classes.locationText}
                //align="center"
              >
                <Box lineHeight={1.3}> {capitalize(trip.destination)}</Box>{" "}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Divider />

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          xs={12}
          sm={12}
          // className={classes.location}
        >
          <SelectedSeats
            buttonVisible={true}
            trip={trip}
            currentSeatModel={currentSeatModel}
            paperVisible={true}
          />
        </Grid>
      </CardContent>
    </Card>
  );
};

const mapState = (state) => ({
  trip: getTrip(state),
  isLoading: getIsLoading(state),
});

const mapDispatch = {
  fetchTrip,
};

export default connect(mapState, mapDispatch)(Main);

import React from 'react';
import { Button, Typography, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { fade } from '@material-ui/core/styles/colorManipulator';


const useStyles=makeStyles((theme)=>({
root:{
    backgroundColor:fade(theme.palette.secondary.light,.3),
    height:50,
    borderBottom:`3px solid ${theme.palette.secondary.light}`,
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    display:'flex',
    alignItems:'flex-end'
},
busCount:{
    fontSize:'18px',
    fontWeight:600,
    color:theme.palette.primary.main
},
filterButton:{
    marginRight:10
}

}))




const Filters=()=>{
    const classes=useStyles();
    return (
        <div className={classes.root}>
       

        <Typography
        component="div"
        className={classes.busCount}
        //align="center"
      >
        <Box lineHeight={1.3}>
          {" "}
          Select seats
        </Box>{" "}
      </Typography>
        </div>
    );
}

export default Filters;
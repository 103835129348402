import {
  Button,
  Grid,
  makeStyles,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import React, { Fragment, useState } from "react";
import Joi from "@hapi/joi";
import { useFormik } from "formik";
import { validatePhoneNumber } from "../../../util/validators";
import { getPassenger } from "../../../redux/accessors/payment";
import { addPassenger } from "../../../redux/actions/payment";
import { connect } from "react-redux";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { fade } from "@material-ui/core/styles/colorManipulator";
import CssTextField from "../../../components/CssTextField";

const useStyles = makeStyles((theme) => ({
  form: {
    width: "100%", // Fix IE 11 issue.
    margin: theme.spacing(4, 0),
    marginBottom: 0,
  },
  saveButton: {
    margin: theme.spacing(4, 0),
    marginBottom: 0,
  },
  textField: {
    borderRadius: "5px",
    border: "none !important",
  },
  textInput: {
    backgroundColor: `${fade(theme.palette.secondary.light, 0.2)} !important`,
    "&::placeholder": {
      fontSize: "14px",
      fontWeight: 500,
    },
    fontSize: "14px",
    fontWeight: 600,

    borderRadius: "5px !important",
  },
  seatNoText: {
    fontSize: "16px",
    fontWeight: 600,
    color: theme.palette.secondary.dark,
  },
  hasLuggageCheckLabel: {
    fontSize: "14px",
    fontWeight: 600,
    color: theme.palette.secondary.dark,
  },
}));

const Main = ({ passenger, addPassenger }) => {
  const classes = useStyles();
  const { object, string, boolean } = Joi.types();

 

  const validationSchema = object.keys({
    firstName: string.required().label("First name"),

    lastName: string.required().label("Last name"),

    nationality: string.required().label("Nationality"),

    id: string.required().label("Id"),

    residence: string.required().label("Residence"),

    phone: string.custom(validatePhoneNumber).required().label("Phone"),

    hasLuggage: boolean.required().label('Has luggage'),

    luggageDescription: string.allow("").label("Luggage description"),
  });

  const validate = (values) => {
    const errors = {};

    const { error } = validationSchema.validate(values);

    if (error)
      error.details.map(({ path, message }, i) => (errors[path[i]] = message));

    return errors;
  };

  const initialValues = {
    firstName: passenger.firstName,
    lastName: passenger.lastName,
    nationality: passenger.nationality,
    id: passenger.id,
    residence: passenger.residence,
    phone: passenger.phone,
    luggageDescription: passenger.luggageDescription,
    hasLuggage: passenger.hasLuggage,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validate,
    onSubmit: (data) => addPassenger(data),
  });

  return (
    <form noValidate className={classes.form} onSubmit={formik.handleSubmit}>
      {/* <Typography className={classes.seatNoText}  gutterBottom>{`Seat No. ${seat_number}`}</Typography> */}
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <CssTextField
            id="firstName"
            name="firstName"
            placeholder="First name"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.firstName}
            error={
              formik.touched.firstName && formik.errors.firstName ? true : false
            }
            helperText={formik.touched.firstName && formik.errors.firstName}
            required
            variant="outlined"
            className={classes.textField}
            InputProps={{
              classes: { input: classes.textInput },
              // startAdornment: (
              //   <InputAdornment position="start">
              //     <AccountCircleIcon className={classes.fromIcon} />
              //   </InputAdornment>
              // ),
            }}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <CssTextField
            id="lastName"
            name="lastName"
            placeholder="Last name"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.lastName}
            error={
              formik.touched.lastName && formik.errors.lastName ? true : false
            }
            helperText={formik.touched.lastName && formik.errors.lastName}
            required
            variant="outlined"
            className={classes.textField}
            InputProps={{
              classes: { input: classes.textInput },
              // startAdornment: (
              //   <InputAdornment position="start">
              //     <AccountCircleIcon className={classes.fromIcon} />
              //   </InputAdornment>
              // ),
            }}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <CssTextField
            id="nationality"
            name="nationality"
            placeholder="Nationality"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.nationality}
            error={
              formik.touched.nationality && formik.errors.nationality
                ? true
                : false
            }
            helperText={formik.touched.nationality && formik.errors.nationality}
            required
            variant="outlined"
            className={classes.textField}
            InputProps={{
              classes: { input: classes.textInput },
              // startAdornment: (
              //   <InputAdornment position="start">
              //     <AccountCircleIcon className={classes.fromIcon} />
              //   </InputAdornment>
              // ),
            }}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <CssTextField
            id="id"
            name="id"
            placeholder="ID / Passport No."
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.id}
            error={formik.touched.id && formik.errors.id ? true : false}
            helperText={formik.touched.id && formik.errors.id}
            required
            variant="outlined"
            className={classes.textField}
            InputProps={{
              classes: { input: classes.textInput },
              // startAdornment: (
              //   <InputAdornment position="start">
              //     <AccountCircleIcon className={classes.fromIcon} />
              //   </InputAdornment>
              // ),
            }}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <CssTextField
            id="residence"
            name="residence"
            placeholder="Residence"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.residence}
            error={
              formik.touched.residence && formik.errors.residence ? true : false
            }
            helperText={formik.touched.residence && formik.errors.residence}
            required
            variant="outlined"
            className={classes.textField}
            InputProps={{
              classes: { input: classes.textInput },
              // startAdornment: (
              //   <InputAdornment position="start">
              //     <AccountCircleIcon className={classes.fromIcon} />
              //   </InputAdornment>
              // ),
            }}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <CssTextField
            id="phone"
            name="phone"
            placeholder="Phone"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.phone}
            error={formik.touched.phone && formik.errors.phone ? true : false}
            helperText={formik.touched.phone && formik.errors.phone}
            required
            variant="outlined"
            className={classes.textField}
            InputProps={{
              classes: { input: classes.textInput },
              // startAdornment: (
              //   <InputAdornment position="start">
              //     <AccountCircleIcon className={classes.fromIcon} />
              //   </InputAdornment>
              // ),
            }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.hasLuggage}
                onChange={formik.handleChange}
                name="hasLuggage"
                color="primary"
              />
            }
            label={
              <Typography className={classes.hasLuggageCheckLabel}>
                I have some luggage
              </Typography>
            }
          />
        </Grid>

        {formik.values.hasLuggage?<Grid item xs={12} lg={6}>
          <CssTextField
            id="luggageDescription"
            name="luggageDescription"
            placeholder="Please enter your luggage description"
            fullWidth
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.luggageDescription}
            error={
              formik.touched.luggageDescription &&
              formik.errors.luggageDescription
                ? true
                : false
            }
            helperText={
              formik.touched.luggageDescription &&
              formik.errors.luggageDescription
            }
            variant="outlined"
            className={classes.textField}
            disabled={!formik.values.hasLuggage}
            InputProps={{
              classes: { input: classes.textInput },
              // startAdornment: (
              //   <InputAdornment position="start">
              //     <AccountCircleIcon className={classes.fromIcon} />
              //   </InputAdornment>
              // ),
            }}
          />
        </Grid>:null}
      </Grid>

      <Button
        color="primary"
        variant="contained"
        className={classes.saveButton}
        type="submit"
      >
        save
      </Button>
    </form>
  );
};

// const Main = ({ seats, passengers, addPassenger }) => {
//   const [selectedSeats, setSelectedSeats] = useState([]);
//   const classes=useStyles();

//   useEffect(() => {
//     setSelectedSeats(filter(flatten(seats), (s) => s && s.selected));
//   }, [seats]);

//   return (
//     <Fragment>
//       <Typography className={classes.seatNoText}>Please enter passenger details</Typography>

//       <form noValidate className={classes.form} onSubmit={formik.handleSubmit}>
//         <Grid container spacing={2}>
//           <Grid item xs={12} lg={6}>
//             <TextField
//               id="firstName"
//               name="firstName"
//               label="First name"
//               fullWidth
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               value={formik.values.firstName}
//               error={
//                 formik.touched.firstName && formik.errors.firstName
//                   ? true
//                   : false
//               }
//               helperText={formik.touched.firstName && formik.errors.firstName}
//               required
//             />
//           </Grid>

//           <Grid item xs={12} lg={6}>
//             <TextField
//               id="lastName"
//               name="lastName"
//               label="Last name"
//               fullWidth
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               value={formik.values.lastName}
//               error={
//                 formik.touched.lastName && formik.errors.lastName ? true : false
//               }
//               helperText={formik.touched.lastName && formik.errors.lastName}
//               required
//             />
//           </Grid>

//           <Grid item xs={12} lg={6}>
//             <TextField
//               id="nationality"
//               name="nationality"
//               label="Nationality"
//               fullWidth
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               value={formik.values.nationality}
//               error={
//                 formik.touched.nationality && formik.errors.nationality
//                   ? true
//                   : false
//               }
//               helperText={
//                 formik.touched.nationality && formik.errors.nationality
//               }
//               required
//             />
//           </Grid>

//           <Grid item xs={12} lg={6}>
//             <TextField
//               id="id"
//               name="id"
//               label="ID / Passport No."
//               fullWidth
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               value={formik.values.id}
//               error={formik.touched.id && formik.errors.id ? true : false}
//               helperText={formik.touched.id && formik.errors.id}
//               required
//             />
//           </Grid>

//           <Grid item xs={12} lg={6}>
//             <TextField
//               id="residence"
//               name="residence"
//               label="Residence"
//               fullWidth
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               value={formik.values.residence}
//               error={
//                 formik.touched.residence && formik.errors.residence
//                   ? true
//                   : false
//               }
//               helperText={formik.touched.residence && formik.errors.residence}
//               required
//             />
//           </Grid>

//           <Grid item xs={12} lg={6}>
//             <TextField
//               id="phone"
//               name="phone"
//               label="Phone"
//               fullWidth
//               onChange={formik.handleChange}
//               onBlur={formik.handleBlur}
//               value={formik.values.phone}
//               error={formik.touched.phone && formik.errors.phone ? true : false}
//               helperText={formik.touched.phone && formik.errors.phone}
//               required
//             />
//           </Grid>
//         </Grid>

//         <Button
//           color="primary"
//           variant="contained"
//           className={classes.saveButton}
//           type="submit"
//         >
//           save
//         </Button>
//       </form>
//     </Fragment>
//   );
// };

const mapState = (state) => ({
  passenger: getPassenger(state),
});

const mapDispatch = {
  addPassenger,
};

export default connect(mapState, mapDispatch)(Main);

import React from "react";
import { Typography, Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 5,
    padding: theme.spacing(1),
    border: `1px solid ${theme.palette.secondary.light}`,
  },
  nameText: {
    fontWeight: 600,
    fontSize: "14px",
    color: theme.palette.secondary.dark,
  },
  labelText: {
    fontWeight: 500,
    fontSize: "12px",
    color: theme.palette.secondary.dark,
    marginRight: 5,
  },
  dataText: {
    fontWeight: 600,
    fontSize: "14px",
    color: theme.palette.secondary.dark,
  },
  dataItem: {
    display: "flex",
  },
}));

const Main = (props) => {
  const { passenger } = props;
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={classes.name}>
            <Typography
              component="div"
              className={classes.nameText}
              //align="center"
            >
              <Box lineHeight={1.3}>
                {" "}
                {passenger.firstName} {`  ${passenger.lastName}`}
                {`,  ${passenger.nationality}`}
              </Box>{" "}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={classes.dataItem}>
            <Typography
              component="div"
              className={classes.labelText}
              //align="center"
            >
              <Box lineHeight={1.3}> Phone number: </Box>{" "}
            </Typography>
            <Typography
              component="div"
              className={classes.dataText}
              //align="center"
            >
              <Box lineHeight={1.3}> {passenger.phone}</Box>{" "}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={classes.dataItem}>
            <Typography
              component="div"
              className={classes.labelText}
              //align="center"
            >
              <Box lineHeight={1.3}> Residence: </Box>{" "}
            </Typography>
            <Typography
              component="div"
              className={classes.dataText}
              //align="center"
            >
              <Box lineHeight={1.3}> {passenger.residence}</Box>{" "}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={classes.dataItem}>
            <Typography
              component="div"
              className={classes.labelText}
              //align="center"
            >
              <Box lineHeight={1.3}> ID/Passport No: </Box>{" "}
            </Typography>
            <Typography
              component="div"
              className={classes.dataText}
              //align="center"
            >
              <Box lineHeight={1.3}> {passenger.id}</Box>{" "}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={classes.dataItem}>
            <Typography
              component="div"
              className={classes.labelText}
              //align="center"
            >
              <Box lineHeight={1.3}> Luggage included: </Box>{" "}
            </Typography>
            <Typography
              component="div"
              className={classes.dataText}
              //align="center"
            >
              <Box lineHeight={1.3}> {passenger.hasLuggage?"Yes": "No"}</Box>{" "}
            </Typography>
          </div>
        </Grid>
        {passenger.hasLuggage?<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={classes.dataItem}>
            <Typography
              component="div"
              className={classes.labelText}
              //align="center"
            >
              <Box lineHeight={1.3}> Luggage description: </Box>{" "}
            </Typography>
            <Typography
              component="div"
              className={classes.dataText}
              //align="center"
            >
              <Box lineHeight={1.3}> {passenger.luggageDescription}</Box>{" "}
            </Typography>
          </div>
        </Grid>:null}
      </Grid>
    </div>
  );
};

export default Main;

import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Typography,
  TableCell,
  TableBody,
  Table,
  TableHead,
  Paper,
  TableRow,
  TableContainer,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { flatten, filter } from "lodash";
import { formatNum } from "../../util/number";
import { useScrollSection } from "react-scroll-section";
import { getSelectedSeats } from "../../redux/accessors/seats";
import { selectSeats } from "../../redux/actions/seats";
import PaymentDetailsDialog from "./PaymentDetailsDialog";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 10,
    alignItems: "flex-end",
  },
  button: {
    margin: theme.spacing(2, 0),
    width: "100%",
  },
  modalPaper: {
    position: "absolute",
    minWidth: 600,
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    border: 0,
  },
  totalFare: {
    paddingTop: 10,
    display: "flex",
  },
  totalFareLabel: {
    fontSize: "14px",
    color: theme.palette.secondary.main,
    alignItems: "flex-end",
    flexGrow: 1,
  },
  tableRow: {
    height: 20,
  },
}));

const Main = ({
  //currentSeatModel,
  trip,
  buttonVisible,
  paperVisible,
  selectedSeats,
  selectSeats,
}) => {
  const classes = useStyles();

  // const [selectedSeats, setSelectedSeats] = useState([]);

  const paymentSection = useScrollSection(`payment`);

  // useEffect(() => {
  //   setSelectedSeats(
  //     filter(flatten(currentSeatModel), (s) => s && s.selected && !s.booked)
  //   );
  // }, [currentSeatModel, setSelectedSeats]);

  const [totalPrice, setTotalPrice] = useState(0);

  //console.log("current seat model, seats", currentSeatModel);

  useEffect(() => {
    let tmp = 0;
    flatten(selectedSeats).map((s) => {
      if (s && s.price) tmp = tmp + s.price;
      return tmp;
    });
    setTotalPrice(tmp);
  }, [setTotalPrice, selectedSeats]);

  return (
    <div className={classes.root}>
      {/* <Typography variant="h5">{`${selectedSeats.length} seats x ${trip.minPrice}/=`}</Typography> */}

      {selectedSeats.length > 0 ? (
        <div>
          <TableContainer component={paperVisible ? Paper : "div"}>
            <Table
              className={classes.table}
              size="small"
              aria-label="seats table"
            >
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell>Seat No.</TableCell>
                  <TableCell align="right">Seat Type</TableCell>
                  <TableCell align="right">Fare (Ksh) </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedSeats.map((seat) => (
                  <TableRow key={seat.seatNo} className={classes.tableRow}>
                    <TableCell component="th" scope="row">
                      {seat.seatNo}
                    </TableCell>
                    <TableCell align="right">
                      {seat.seatType == "firstClass"
                        ? "First Class"
                        : seat.seatType == "preniumClass"
                        ? "Premium Class"
                        : "Economy Class"}
                    </TableCell>
                    <TableCell align="right">{seat.price}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : null}
      {/* {selectedSeats? <div>
       
       {
           selectedSeats&&selectedSeats.map(seat=>(
           <div key={seat.seatNo}>
             <Typography key variant="h4" className={classes.totalFareLabel}>
           {`Seat No.: ${seat.seatNo}  Fare: ${seat.price} Seat Type: ${seat.seatType}`}
         </Typography>
         </div>
         
         ))
        }</div>:null} */}
      <div className={classes.totalFare}>
        <Typography variant="h4" className={classes.totalFareLabel}>
          {`Total fare (${selectedSeats.length} seats)`}
        </Typography>
        <Typography align="right" variant="h4">{`Ksh. ${formatNum(
          totalPrice
        )} /=`}</Typography>
      </div>
      {buttonVisible ? (
        <PaymentDetailsDialog
          selectSeats={selectSeats}
          selectedSeats={selectedSeats}
          trip={trip}
        />
      ) : null}
      {/* {buttonVisible ? (
        <Button
          onClick={
            paymentSection.onClick
            // window.location.href=`/trip/${trip._id}#payment`
          }
          className={classes.button}
          disabled={!selectedSeats.length ? true : false}
          variant="contained"
          color="primary"
          href={`/trip/${trip._id}#payment`}
        >
          Proceed
        </Button>
      ) : null} */}
    </div>
  );
};

const mapState = (state) => ({
  selectedSeats: getSelectedSeats(state),
});

const mapDispatch = {
  selectSeats,
};

export default connect(mapState, mapDispatch)(Main);

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
  Box,
  Divider,
  Container,
} from "@material-ui/core";
import { green, yellow } from "@material-ui/core/colors";
import React, { Fragment } from "react";
import { connect } from "react-redux";
//import { getPassenger } from "../../../../redux/accessors/payment";
import { capitalize } from "lodash";
import moment from "moment";
import { timeFormat } from "../../../../util/time";
//import { getTrip } from "../../../../redux/accessors/trip";
import { getTicketData } from "../../../../redux/accessors/payment";
import { formatNum } from "../../../../util/number";
import TicketPdf from "./TicketPdf";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import Logo from "../../../../static/white-logo.png";
import SafetyBanner from "../../../../static/safety.png";
import { fade } from "@material-ui/core/styles/colorManipulator";
import LocationSearchingIcon from "@material-ui/icons/LocationSearching";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { ReactComponent as FaceMask } from "../../../../static/svg/face-mask.svg";
import { ReactComponent as Sanitizer } from "../../../../static/svg/hand-sanitizer.svg";
import { ReactComponent as Chairs } from "../../../../static/svg/chairs.svg";
import { getTrip } from "../../../../redux/accessors/trip";

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(1),
    border: `2px dashed ${theme.palette.secondary.dark}`,
    // [theme.breakpoints.up("xs")]: {
    //   paddingTop: 5,
    // },
    // [theme.breakpoints.down("sm")]: {
    //   paddingTop: 0,
    // },
  },
  title: {
    fontSize: 16,
    fontWeight: 600,
    color: "white",
  },
  subTitle: {
    fontSize: 14,
    fontWeight: 500,
    color: "white",
  },

  header: {
    backgroundColor: theme.palette.primary.main,
    height: 60,
    color: "white",
    [theme.breakpoints.down("sm")]: {
      height: 80,
      padding: 3,
      paddingBottom: 20,
    },
  },
  logo: {
    maxWidth: 100,
    margin: 10,
    marginLeft: 30,
    [theme.breakpoints.down("sm")]: {
      margin: 5,
      marginLeft: 10,
      maxWidth: 100,
      marginTop: 10,
    },
  },
  banner: {
    maxWidth: 100,
    height: 450,
  },
  tripID: {
    padding: 2,
    //height: 25,
    backgroundColor: fade(theme.palette.secondary.light, 0.3),
  },
  luggageTitle: {
    padding: 2,
    //height: 25,
    backgroundColor: fade(theme.palette.secondary.light, 0.3),
    borderTop: `2px dashed ${theme.palette.secondary.dark}`,
  },
  smallText: {
    fontSize: 12,
    color: "#000000",
    marginLeft: 30,
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
    },
  },
  luggageTitleText: {
    fontSize: 16,
    color: "#000000",
    marginLeft: 30,
    fontWeight: 600,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 15,
    },
  },
  labelText: {
    fontSize: 12,
    color: theme.palette.secondary.dark,
    marginLeft: 0,
    fontWeight: 600,
  },
  locationText: {
    fontSize: "14px",
    fontWeight: 600,
    marginLeft: 5,
    marginBottom: 5,
    color: theme.palette.secondary.main,
  },
  totalFareText: {
    fontSize: "16px",
    fontWeight: 600,
    marginLeft: 5,
    color: theme.palette.secondary.main,
  },
  departureText: {
    fontSize: "14px",
    fontWeight: 600,
    marginLeft: 5,
    color: theme.palette.secondary.main,
    marginBottom: 20,
  },
  divider: {
    height: 20,
    width: 2,
    backgroundColor: "none",
    marginLeft: 8,
    margin: 0,
    border: `1px dashed ${theme.palette.secondary.light}`,
  },
  fromLocationIcon: {
    color: theme.palette.primary.main,
    fontSize: "18px",
    fontWeight: 900,
    marginRight: 5,
  },
  toLocationIcon: {
    color: green[700],
    fontSize: "20px",
    fontWeight: 900,
    marginRight: 4,
  },
  locationDiv: {
    display: "flex",
    alignItems: "center",
    //margin:10
    //marginBottom:10
  },
  locationSection: {
    padding: 10,
    marginLeft: 20,
    [theme.breakpoints.down("sm")]: {
      marginLeft: 10,
    },
  },
  covidSafety: {
    // display:'flex',
    // flexDirection:'column',
    borderRight: `2px dashed ${theme.palette.secondary.dark}`,
    backgroundColor: yellow[500],
    width: "100%",
    height: "100%",
  },
  covidContent: {
    // display:'flex',
    // flexDirection:'column',

    backgroundColor: "red",
    width: "100%",
    height: "500px",
  },
  svgSanitizer: {
    // transform: 'rotate(-90deg)',
    marginTop: 20,
    height: 30,
    width: 30,
  },
  svgMask: {
    // transform: 'rotate(-90deg)',
    marginTop: 0,
    height: 30,
    width: 30,
  },
  svgChairs: {
    // transform: 'rotate(-90deg)',
    height: 50,
    width: 50,
    marginLeft: 10,
  },
  svgMargin: {
    // marginLeft:10
  },
  safetyText: {
    marginTop: 70,
    fontSize: 36,
    fontWeight: 600,
    color: theme.palette.secondary.dark,
    transform: "rotate(-90deg)",
    // -webkit-transform: 'rotate(-90deg)',
    // -moz-transform:' rotate(-90deg)',
    // -o-transform: 'rotate(-90deg)',
    // -ms-transform:' rotate(-90deg)',
  },
  featureText: {
    // transform: 'rotate(-90deg)',
    fontSize: 12,
    color: theme.palette.black,
    marginLeft: 0,
    fontWeight: 600,
  },
  downloadPDF: {
    display: "flex",
    justifyContent: "flex-end",
  },
  downloadButton: {
    marginRight: 20,
    textDecoration: "none",
  },
}));

const Main = ({ ticketData, trip }) => {
  const classes = useStyles();

  console.log("ticket data", ticketData);

  return (
    <Fragment>
      <div className={classes.card}>
        {/* <Grid container>
          <Grid item xs={0} sm={0} md={1} lg={1} xl={1}>
          <img className={classes.banner} src={SafetyBanner} alt="logo" />
            <div className={classes.covidSafety}>
              <Box display="flex" flexDirection="column">
                <Box p={1}  style={{ height: 110 }}>
                <Sanitizer className={classes.svgSanitizer} fill="#000000" />
                <Typography className={classes.featureText} align='center'>
                   Sanitized Bus
                  </Typography>
                </Box>
                <Box p={1}  style={{ height: 115 }}>
               
                  <FaceMask className={classes.svgMask} fill="#000000" />
                  
                <Typography className={classes.featureText} align='center'>
                Mandatory Masks
                  </Typography>
                </Box>
                <Box
                  p={1}
                  justifyContent="center"
                 
                  style={{ height: 110 }}
                >
                <Chairs className={classes.svgChairs} fill="#000000" />
                  <Typography className={classes.featureText} align='center'>
                    Social Distance
                  </Typography>
                </Box>
                <Box p={1}  style={{ height: 130 }}>
                  <Typography className={classes.safetyText} >
                    Safety+
                  </Typography>
                </Box>
              </Box>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={11} lg={11} xl={11}> */}
        <Grid container spacing={0}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.header}>
              <Grid container spacing={1}>
                <Grid item xs={5} sm={5} md={3} lg={3} xl={3}>
                  <img className={classes.logo} src={Logo} alt="logo" />
                </Grid>
                <Grid item xs={7} sm={7} md={5} lg={5} xl={5}>
                  <Typography className={classes.title}>
                    {trip.saccoName}
                  </Typography>

                  <Typography className={classes.subTitle}>
                    {ticketData.vehicleno}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <Typography
                    color="textSecondary"
                    align="center"
                    className={classes.title}
                  >
                    Bus Ticket
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className={classes.tripID}>
              <Typography color="textSecondary" className={classes.smallText}>
                {" "}
                Trip ID: {ticketData.tripId}
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <div className={classes.locationSection}>
                  <div className={classes.locationDiv}>
                    <LocationSearchingIcon
                      className={classes.fromLocationIcon}
                    />{" "}
                    <Typography
                      component="div"
                      className={classes.locationText}
                      //align="center"
                    >
                      <Box lineHeight={1.3} className={classes.labelText}>
                        {" "}
                        From
                      </Box>{" "}
                      <Box lineHeight={1.3}> {ticketData.departure}</Box>{" "}
                    </Typography>
                  </div>
                  <Divider
                    orientation="vertical"
                    flexItem
                    className={classes.divider}
                  />
                  <div className={classes.locationDiv}>
                    <LocationOnIcon className={classes.toLocationIcon} />{" "}
                    <Typography
                      component="div"
                      className={classes.locationText}
                      //align="center"
                    >
                      <Box lineHeight={1.3} className={classes.labelText}>
                        {" "}
                        To
                      </Box>{" "}
                      <Box lineHeight={1.3}> {ticketData.destination}</Box>{" "}
                    </Typography>
                  </div>
                </div>
              </Grid>

              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <div className={classes.locationSection}>
                  <Typography
                    component="div"
                    className={classes.departureText}
                    //align="center"
                  >
                    <Box lineHeight={1.3} className={classes.labelText}>
                      {" "}
                      Departure date
                    </Box>{" "}
                    <Box lineHeight={1.3}> {ticketData.departureTime}</Box>{" "}
                  </Typography>
                  <Typography
                    component="div"
                    className={classes.locationText}
                    //align="center"
                  >
                    <Box lineHeight={1.3} className={classes.labelText}>
                      {" "}
                      Booking date
                    </Box>{" "}
                    <Box lineHeight={1.3}> November 23, 2020</Box>{" "}
                  </Typography>
                </div>
              </Grid>

              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <div className={classes.locationSection}>
                  <Typography
                    component="div"
                    className={classes.departureText}
                    //align="center"
                  >
                    <Box lineHeight={1.3} className={classes.labelText}>
                      {" "}
                      Total seats
                    </Box>{" "}
                    <Box lineHeight={1.3}> {ticketData.numberOfSeat}</Box>{" "}
                  </Typography>
                  <Typography
                    component="div"
                    className={classes.locationText}
                    //align="center"
                  >
                    <Box lineHeight={1.3} className={classes.labelText}>
                      {" "}
                      Seat numbers
                    </Box>{" "}
                    <Box lineHeight={1.3}>
                      {ticketData.selectedSeatNo.map((seat) => seat)}
                    </Box>{" "}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <div className={classes.locationSection}>
                  <Typography
                    component="div"
                    className={classes.departureText}
                    //align="center"
                  >
                    <Box lineHeight={1.3} className={classes.labelText}>
                      {" "}
                      Passenger
                    </Box>{" "}
                    <Box lineHeight={1.3}>
                      {" "}
                      {ticketData.passengerName}, {ticketData.nationality}
                    </Box>{" "}
                  </Typography>
                  <Typography
                    component="div"
                    className={classes.locationText}
                    //align="center"
                  >
                    <Box lineHeight={1.3} className={classes.labelText}>
                      {" "}
                      Phone
                    </Box>{" "}
                    <Box lineHeight={1.3}>{ticketData.phone}</Box>{" "}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                <div className={classes.locationSection}>
                  <Typography
                    component="div"
                    className={classes.departureText}
                    //align="center"
                  >
                    <Box lineHeight={1.3} className={classes.labelText}>
                      {" "}
                      Residence
                    </Box>{" "}
                    <Box lineHeight={1.3}>{ticketData.residence}</Box>{" "}
                  </Typography>
                  <Typography
                    component="div"
                    className={classes.locationText}
                    //align="center"
                  >
                    <Box lineHeight={1.3} className={classes.labelText}>
                      {" "}
                      Passenger ID No./Passport No.
                    </Box>{" "}
                    <Box lineHeight={1.3}>{ticketData.nationalId}</Box>{" "}
                  </Typography>
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                <div className={classes.locationSection}>
                  <Typography
                    component="div"
                    className={classes.departureText}
                    //align="center"
                  >
                    <Box lineHeight={1.3} className={classes.labelText}>
                      {" "}
                      Luggage
                    </Box>{" "}
                    <Box lineHeight={1.3}>
                      {ticketData.hasLuggage
                        ? ticketData.luggageDescription
                        : "No luggage included"}
                    </Box>{" "}
                  </Typography>
                  <Typography
                    component="div"
                    className={classes.totalFareText}
                    //align="center"
                  >
                    <Box lineHeight={1.3} className={classes.labelText}>
                      {" "}
                      Total fare
                    </Box>{" "}
                    <Box lineHeight={1.3}>Ksh. {ticketData.amount}</Box>{" "}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Grid>
          {ticketData.hasLuggage ? (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <div className={classes.luggageTitle}>
                    <Typography
                      color="textSecondary"
                      className={classes.luggageTitleText}
                    >
                      {" "}
                      LUGGAGE TAG
                    </Typography>

                    <Typography
                      color="textSecondary"
                      className={classes.smallText}
                    >
                      {" "}
                      Trip ID:{ticketData.tripId}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <div className={classes.locationSection}>
                    <Typography
                      component="div"
                      className={classes.departureText}
                      //align="center"
                    >
                      <Box lineHeight={1.3} className={classes.labelText}>
                        {" "}
                        Passenger
                      </Box>{" "}
                      <Box lineHeight={1.3}>
                        {ticketData.passengerName}, {ticketData.nationality}
                      </Box>{" "}
                    </Typography>
                    <Typography
                      component="div"
                      className={classes.locationText}
                      //align="center"
                    >
                      <Box lineHeight={1.3} className={classes.labelText}>
                        {" "}
                        Phone
                      </Box>{" "}
                      <Box lineHeight={1.3}>{ticketData.phone}</Box>{" "}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4} xl={4}>
                  <div className={classes.locationSection}>
                    <Typography
                      component="div"
                      className={classes.departureText}
                      //align="center"
                    >
                      <Box lineHeight={1.3} className={classes.labelText}>
                        {" "}
                        From
                      </Box>{" "}
                      <Box lineHeight={1.3}>{ticketData.departure}</Box>{" "}
                    </Typography>
                    <Typography
                      component="div"
                      className={classes.locationText}
                      //align="center"
                    >
                      <Box lineHeight={1.3} className={classes.labelText}>
                        {" "}
                        To
                      </Box>{" "}
                      <Box lineHeight={1.3}>{ticketData.destination}</Box>{" "}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                  <div className={classes.locationSection}>
                    <Typography
                      component="div"
                      className={classes.departureText}
                      //align="center"
                    >
                      <Box lineHeight={1.3} className={classes.labelText}>
                        {" "}
                        Bus
                      </Box>{" "}
                      <Box lineHeight={1.3}>
                        {trip.saccoName}, {ticketData.vehicleno}
                      </Box>{" "}
                    </Typography>
                    <Typography
                      component="div"
                      className={classes.locationText}
                      //align="center"
                    >
                      <Box lineHeight={1.3} className={classes.labelText}>
                        {" "}
                        Luggage description
                      </Box>{" "}
                      <Box lineHeight={1.3}>
                        {ticketData.luggageDescription}
                      </Box>{" "}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>{" "}
        {/* </Grid>
        </Grid> */}
      </div>
      
      <div className={classes.downloadPDF}>
     
        <PDFDownloadLink
          className={classes.downloadButton}
          document={<TicketPdf ticketData={ticketData} trip={trip} />}
          fileName={`${new Date().getTime()}.pdf`}
        >
          {({ blob, url, loading, error }) =>
            loading ? (
              "Loadin document"
            ) : (
              <Button size="small" color="primary" variant="contained" >
                Download Ticket
              </Button>
            )
          }
        </PDFDownloadLink>
        
        <Button
        className={classes.button}
        variant="outlined"
        color="primary"
        href={`/`}
        size="small"
      >
        Go back home
      </Button>
        {/* <PDFViewer>
    <TicketPdf ticketData={ticketData} trip={trip} />
  </PDFViewer> */}
      </div>
    </Fragment>
  );
};

const mapState = (state) => ({
  //passenger: getPassenger(state),
  //trip: getTrip(state),
  trip: getTrip(state),
  ticketData: getTicketData(state),
});

export default connect(mapState)(Main);

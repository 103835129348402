import { concat } from "lodash";
import {
  TOGGLE_SELECTED,
  START_LOADING,
  ADD_SELECTED_SEAT,
  REMOVE_SELECTED_SEAT,
  LOAD_SEATS,
  LOAD_FOURTEEN_SEATER_MODEL,
  LOAD_THIRTY_THREE_SEATER_MODEL,
  LOAD_TWENTY_FIVE_SEATER_MODEL,
  END_LOADING,
  OPEN_DIALOG,
  UNSELECT_ALL_SEATS
} from "../constants/seats";
import { find, remove } from "lodash";

const initialState = {
  isLoading: false,
  isDialogOpen:false,
  selectedSeats: [],
  seats: [
    [
      { seat_number: "DRV", reserved: true },
      { seat_number: 25, reserved: true },
      { seat_number: 26, reserved: true },
      { seat_number: 27, reserved: true },
      { seat_number: 28, reserved: true },
      { seat_number: 29, reserved: true },
      { seat_number: 30, reserved: true },
      { seat_number: 31, reserved: true },
      { seat_number: 32, reserved: true },
    ],
    [
      { seat_number: null, display: false },
      { seat_number: 17, reserved: true },
      { seat_number: 18, reserved: true },
      { seat_number: 19, reserved: true },
      { seat_number: 20, reserved: true },
      { seat_number: 21, reserved: true },
      { seat_number: 22, reserved: true },
      { seat_number: 23, reserved: true },
      { seat_number: 24, reserved: true },
    ],
    [null, null, null, null, null, null, null, null, null],
    [
      { seat_number: null, display: false },
      { seat_number: 9, reserved: true },
      { seat_number: 10, reserved: true },
      { seat_number: 11, reserved: true },
      { seat_number: 12, reserved: true },
      { seat_number: 13, reserved: true },
      { seat_number: 14, reserved: true },
      { seat_number: 15, reserved: true },
      { seat_number: 16, reserved: true },
    ],
    [
      { seat_number: "STF", reserved: true },
      { seat_number: 1, reserved: true },
      { seat_number: 2, reserved: true },
      { seat_number: 3, reserved: true },
      { seat_number: 4, reserved: true },
      { seat_number: 5, reserved: true },
      { seat_number: 6, reserved: true },
      { seat_number: 7, reserved: true },
      { seat_number: 8, reserved: true },
    ],
  ],
  fourteenSeaterModel: [
    [
      { seatNo: "DRV", disabled: true },
      { seatNo: 5 },
      { seatNo: 8 },
      { seatNo: 11 },
      { seatNo: 14 },
    ],
    [
      { seatNo: 2 },
      { seatNo: 4 },
      { seatNo: 7 },
      { seatNo: 10 },
      { seatNo: 13 },
    ],
    [
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 0 },
    ],
    [
      { seatNo: 1 },
      { seatNo: 3 },
      { seatNo: 6 },
      { seatNo: 9 },
      { seatNo: 12 },
    ],
  ],
  twentyFiveSeaterModel: [
    [
      { seatNo: "DRV", disabled: true },
      { seatNo: 6 },
      { seatNo: 8 },
      { seatNo: 12 },
      { seatNo: 16 },
      { seatNo: 20 },
      { seatNo: 25 },
    ],
    [
      { seatNo: 0 },
      { seatNo: 5 },
      { seatNo: 7 },
      { seatNo: 11 },
      { seatNo: 15 },
      { seatNo: 19 },
      { seatNo: 24 },
    ],
    [
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 23 },
    ],
    [
      { seatNo: 2 },
      { seatNo: 4 },
      { seatNo: 0 },
      { seatNo: 10 },
      { seatNo: 14 },
      { seatNo: 18 },
      { seatNo: 22 },
    ],
    [
      { seatNo: 1 },
      { seatNo: 3 },
      { seatNo: 0 },
      { seatNo: 9 },
      { seatNo: 13 },
      { seatNo: 17 },
      { seatNo: 21 },
    ],
  ],
  thirtyThreeSeaterModel: [
    [
      { seatNo: "DRV", disabled: true },
      { seatNo: 6 },
      { seatNo: 8 },
      { seatNo: 12 },
      { seatNo: 16 },
      { seatNo: 20 },
      { seatNo: 24 },
      { seatNo: 28 },
      { seatNo: 33 },
    ],
    [
      { seatNo: 0 },
      { seatNo: 5 },
      { seatNo: 7 },
      { seatNo: 11 },
      { seatNo: 15 },
      { seatNo: 19 },
      { seatNo: 23 },
      { seatNo: 27 },
      { seatNo: 32 },
    ],
    [
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 0 },
      { seatNo: 31 },
    ],
    [
      { seatNo: 2 },
      { seatNo: 4 },
      { seatNo: 0 },
      { seatNo: 10 },
      { seatNo: 14 },
      { seatNo: 18 },
      { seatNo: 22 },
      { seatNo: 26 },
      { seatNo: 30 },
    ],
    [
      { seatNo: 1 },
      { seatNo: 3 },
      { seatNo: 0 },
      { seatNo: 9 },
      { seatNo: 13 },
      { seatNo: 17 },
      { seatNo: 21 },
      { seatNo: 25 },
      { seatNo: 29 },
    ],
  ],
};

const main = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_SELECTED:
      return {
        ...state,
        seats: state.seats.map((row, rowIndex) => {
          return row.map((col, colIndex) => {
            if (
              rowIndex === action.position[0] &&
              colIndex === action.position[1]
            )
              state.seats[rowIndex][colIndex].selected = !state.seats[rowIndex][
                colIndex
              ].selected;

            return col;
          });
        }),
      };

    case START_LOADING:
      return { ...state, isLoading: true };

    case ADD_SELECTED_SEAT:
      return {
        ...state,
        selectedSeats: concat(state.selectedSeats, action.selectedSeat),
      };

    case REMOVE_SELECTED_SEAT:
      return {
        ...state,
        selectedSeats: remove(
          state.selectedSeats,
          (s) => s.seatNo !== action.selectedSeat.seatNo
        ),
      };
    
      case UNSELECT_ALL_SEATS:
        return {
          ...state,
          selectedSeats:[]
        }

    case LOAD_SEATS:
      const sortedSeats = state.seats.map((row) =>
        row.map((col) => {
          if (col) {
            const seat = find(action.seats, (s) => s.seatNo === col.seatNumber);

            if (seat) {
              return { ...col, id: seat._id, reserved: false };
            } else {
              return { ...col, reserved: true };
            }
          } else {
            return col;
          }
        })
      );

      return { ...state, seats: sortedSeats };

    case LOAD_FOURTEEN_SEATER_MODEL:
      return {
        ...state,
        fourteenSeaterModel: state.fourteenSeaterModel.map((row) =>
          row.map((col) => {
            if (col) {
              const seat = find(action.seats, (s) => s.seatNo === col.seatNo);

              if (seat) {
                return { ...col, ...seat };
              } else {
                return { ...col };
              }
            } else {
              return col;
            }
          })
        ),
      };

    case LOAD_THIRTY_THREE_SEATER_MODEL:
      return {
        ...state,
        thirtyThreeSeaterModel: state.thirtyThreeSeaterModel.map((row) =>
          row.map((col) => {
            if (col) {
              const seat = find(action.seats, (s) => s.seatNo === col.seatNo);

              if (seat) {
                return { ...col, ...seat };
              } else {
                return { ...col };
              }
            } else {
              return col;
            }
          })
        ),
      };

    case LOAD_TWENTY_FIVE_SEATER_MODEL:
      return {
        ...state,
        twentyFiveSeaterModel: state.twentyFiveSeaterModel.map((row) =>
          row.map((col) => {
            if (col) {
              const seat = find(action.seats, (s) => s.seatNo === col.seatNo);

              if (seat) {
                return { ...col, ...seat };
              } else {
                return { ...col };
              }
            } else {
              return col;
            }
          })
        ),
      };

    case END_LOADING:
      return { ...state, isLoading: false };
    case OPEN_DIALOG:
      return {...state, isDialogOpen:action.isDialogOpen}

    default:
      return state;
  }
};

export default main;

export const TOGGLE_SELECTED = "TOGGLE_SELECTED";

export const START_LOADING = "START_LOADING";

export const ADD_SELECTED_SEAT = "ADD_SELECTED_SEAT";

export const REMOVE_SELECTED_SEAT = "REMOVE_SELECTED_SEAT";

export const LOAD_SEATS = "LOAD_SEATS";

export const LOAD_FOURTEEN_SEATER_MODEL = "LOAD_FOURTEEN_SEATER_MODEL";

export const LOAD_THIRTY_THREE_SEATER_MODEL = "LOAD_THIRTY_THREE_SEATER_MODEL";

export const LOAD_TWENTY_FIVE_SEATER_MODEL = "LOAD_TWENTY_FIVE_SEATER_MODEL";

export const END_LOADING = "END_LOADING";

export const OPEN_DIALOG="OPEN_DIALOG";

export const UNSELECT_ALL_SEATS="UNSELECT_ALL_SEATS";

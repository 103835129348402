import React from "react";
import SearchForm from "./SearchForm";
import SearchResults from "./SearchResults";
import Filters from "./Filters";
import { motion } from "framer-motion";

const Main = () => {
  const containerVariants = {
    hidden: {
     // x:'+50vw',
      opacity: 0.5,
      scale:1.2
    },
    visible: {
   //   x:'0px',
      opacity: 1,
      scale:1,
      transition: { delay: 0.0, duration: 0.5 },
    },
    exit: {
      x: "-100vw",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  };
  return (
    <motion.main
      variants={containerVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <SearchForm />
      <Filters />
      <SearchResults />
    </motion.main>
  );
};

export default Main;

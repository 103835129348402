import {
  START_LOADING,
  LOAD,
  END_LOADING,
  START_LOADING_FROM_OPTIONS,
  LOAD_FROM_OPTIONS,
  END_LOADING_FROM_OPTIONS,
  START_LOADING_TO_OPTIONS,
  LOAD_TO_OPTIONS,
  END_LOADING_TO_OPTIONS,
} from "../constants/search";

const initialState = {
  isLoading: false,
  trips: [],
  fromIsLoading: false,
  fromOptions: [],
  toIsLoading: false,
  toOptions: [],
};

const main = (state = initialState, action) => {
  switch (action.type) {
    case START_LOADING:
      return { ...state, isLoading: true };

    case LOAD:
      return { ...state, trips: action.payload };

    case END_LOADING:
      return { ...state, isLoading: false };

    case START_LOADING_FROM_OPTIONS:
      return { ...state, fromIsLoading: true };

    case LOAD_FROM_OPTIONS:
      return { ...state, fromOptions: action.options };

    case END_LOADING_FROM_OPTIONS:
      return { ...state, fromIsLoading: false };

    case START_LOADING_TO_OPTIONS:
      return { ...state, toIsLoading: true };

    case LOAD_TO_OPTIONS:
      return { ...state, toOptions: action.options };

    case END_LOADING_TO_OPTIONS:
      return { ...state, toIsLoading: false };

    default:
      return state;
  }
};

export default main;

const accessor = ({ reducer: { payment } }) => payment;

export const getIsLoading = (state) => accessor(state).isLoading;

export const getPassenger = (state) => accessor(state).passenger;

export const getRequest = (state) => accessor(state).request;

export const getPaymentConfirmed=(state)=>accessor(state).paymentConfirmed;

export const getPaymentPromptSent=(state)=>accessor(state).paymentPromptSent;

export const getTicketData=(state)=>accessor(state).ticketData;

export const getActiveStep=(state)=>accessor(state).activeStep;
